import moment from 'moment'
import Swal from 'sweetalert2'

const container = document.getElementById('fakeTime');
container.style.display = 'none';

container.addEventListener('click', async function(){

	if (get()){
		remove();
		window.location = window.location.pathname;
		return;
	}

	const { value: setDate } = await Swal.fire({
		title: 'What date/time to fake?',
		input: 'text',
		inputPlaceholder: 'April 15 2019, 10:00'
	});

	if (!setDate) return;

	set(new Date(setDate));
	window.location = window.location.pathname;

});

let interval;

function set(val){
	localStorage.setItem('fake-time', JSON.stringify({
		time: val,
		created_at: new Date(),
	}));
}

function remove(){
	localStorage.removeItem('fake-time');
	container.innerText = 'Fake time';
}

function get(){

	try{
		let d = JSON.parse(localStorage.getItem('fake-time'));
		return d;
	} catch (e){
		console.error(e);
	}

}

function getTime(){
	let d = this.get();
	if (!d) return;
	let timeDiff = new Date().getTime() - new Date(d.created_at).getTime();
	return new Date(new Date(d.time).getTime()+timeDiff);
}

function enable(){
	container.style.display = 'block';

	interval = setInterval(function(){

		let time = this.getTime();
		if (time) container.innerText = 'Faking time: ' + moment(new Date(time)).format('YYYY/MM/DD, h:mm a');

	}.bind(this), 1000);

}

function disable(){
	if (interval) clearInterval(interval);
	container.style.display = 'none';
}

export default {
	set,
	get,
	remove,
	getTime,
	enable,
	disable
}