const queryString = require('query-string');
const container = document.getElementById('team');
const {TEAMS, POSITIONS} = require('../constants');
import WeekSelector from '../components/week_selector';
import Swal from 'sweetalert2'
import tippy from 'tippy.js';
import { getWeek, findGameFromSeasonData, getGameStatusString, getGameScoreString, getVsString } from '../helpers';
import createCache from '../cache';


const weekSelector = new WeekSelector(container.querySelector('select[name="week"]'));
weekSelector.setOnChangeListener(value => {
	app.navigate(`${window.location.pathname}?week=${value}`);
});

let league;
let season;

const teamData = createCache({
	fetch: (teamId) => {
		return () => createCache({
			fetch: (week) => {
				console.log('fetching players', week)
				return () => app.api.get(`/fantasy/team/${teamId}`, {week});
			}
		})
	}
})

async function show(leagueData, seasonData, teamId){

	if (leagueData) league = leagueData;

	const qs = queryString.parse(window.location.search);
	let week = qs.week

	if (seasonData){
		season = seasonData;
		week = getWeek(week, season)
		weekSelector.populate(seasonData, week);
	}

	weekSelector.select(week)

	const currentTeam = await teamData.get(teamId);
	let data = await currentTeam.get(Number(week))
	const {team, stats, positions_stats} = data

	const weekRank = stats.week_rank;

	container.querySelector('.seasonPoints .value').innerText = (stats.season_points || 0).toFixed(2);
	container.querySelector('.weekRank .value').innerText = weekRank ? `${weekRank} of ${leagueData.teams.length}` : '?';
	container.querySelector('.seasonRank .value').innerText = stats.season_rank ? `${stats.season_rank} of ${leagueData.teams.length}` : '?';
	container.querySelector('.weekWins .value').innerText = stats.week_wins || 0;
	container.querySelector('.weeklyPoints .value').innerText = `${(stats.week_points || 0).toFixed(2)} pts`;

	tippy('.weeklyPoints .info-tooltip', {
		content: `
		Select your players before their games kick off.

		You can make changes up until each player’s game kicks off, but once a selected player is involved in a game that’s started, that player will be locked in.

		Good Luck!`
	});

	app.setTitle(`Team: ${team.name} in ${league.name}`);

	container.querySelector('h1').innerText = `${team.name}`;

	container.querySelectorAll('div.items').forEach(function(item){
		item.innerHTML = '';
	});

	container.querySelector('.table').scrollLeft = 0;

	let groups = {};
	let groupsContent = {};

	POSITIONS.groups.map(function(group, index){

		groupsContent[index] = [];

		group.map(function(position){
			groups[position] = index;
		});
	});

	let selectedWeek = weekSelector.value;

	let positionsFilled = [];

	team.positions.map(function(item){
		item._order = POSITIONS.types.indexOf(item.position);
		return item;
	}).sort(function(a, b){
		return a._order - b._order;
	}).map(function(item){

		if (team.user != app.auth.getUserId()) item.locked = true;

		let name = TEAMS[item.team] ? TEAMS[item.team].full_name : item.team;
		if (item.player) name = `${item.player.first_name} ${item.player.last_name}`;

		if (item.week != selectedWeek) return;

		positionsFilled.push(item.position);


		if (item && positions_stats && positions_stats[item.player ? item.player._id : item.team]){
			item.stats = positions_stats[item.player ? item.player._id : item.team].stats;
			item.fan_pts = positions_stats[item.player ? item.player._id : item.team].fan_pts;
		}

		if (!item.stats) item.stats = {};

		//console.log(vsGame);

		let posStatsHTML;

		switch(item.position){

			case 'K':

			posStatsHTML = `
			<div class="column flex">
			<span>${item.stats.extra_points_made || 0}</span>
			<span>${item.stats.extra_points_attempted || 0}</span>
			</div>

			<div class="column flex">
			<span>${item.stats.field_goals_made0to19 || 0}</span>
			<span>${item.stats.field_goals_made20to29 || 0}</span>
			</div>

			<div class="column flex">

			<span>${item.stats.field_goals_made30to39 || 0}</span>
			<span>${item.stats.field_goals_made40to49 || 0}</span>
			<span>${item.stats.field_goals_made50_plus || 0}</span>

			</div>
			`;

			break;

			case 'DEF':

			posStatsHTML = `
			<div class="column flex">
			<span>${item.stats.points_allowed || 0}</span>
			<span>${item.stats.sacks || 0}</span>
			</div>

			<div class="column flex">
			<span>${item.stats.interceptions || 0}</span>
			<span>${item.stats.fumbles_recovered || 0}</span>
			</div>

			<div class="column flex">

			<span>${item.stats.punt_return_touchdowns || 0}</span>
			<span>${item.stats.blocked_kicks || 0}</span>

			</div>`;

			break;

			default:

			posStatsHTML = `
			<div class="column flex">


			<span>${item.stats.passing_yards || 0}</span>
			<span>${item.stats.passing_touchdowns || 0}</span>
			<span>${item.stats.passing_interceptions || 0}</span>

			</div>

			<div class="column flex">

			<span>${item.stats.rushing_attempts || 0}</span>
			<span>${item.stats.rushing_yards || 0}</span>
			<span>${item.stats.rushing_touchdowns || 0}</span>
			</div>

			<div class="column flex">

			<span>${item.stats.receiving_targets || 0}</span>
			<span>${item.stats.receptions || 0}</span>
			<span>${item.stats.receiving_yards || 0}</span>
			<span>${item.stats.receiving_touchdowns || 0}</span>

			</div>
			`;

			break;

		}

		const vsGame = findGameFromSeasonData(week, item.team, season);
		const gameStatus = getGameStatusString(item.team, vsGame)
		const gameScore = getGameScoreString(item.team, vsGame);
		const vs = getVsString(item.team, vsGame);

		const gameStatusEl = `<span class="gameStatus">${gameStatus}<br />${gameScore ? gameScore + ' ' : ''}${vs}</span>`;

		let html = `<div class="item flex item-used ${item.locked ? 'item-locked' : ''}" data-id="${item._id}"
		data-position="${item.position}"
		data-name="${name.escapeHTML()}"
		onclick="app.modules.get('team').positionClick.bind(this)();">

		<div class="column name">
		<div class="position">
		<span class="name">${item.position}</span>
		<span class="player-name">${name.escapeHTML()}</span>
		<span class="team">${item.player ? item.player.status == 'questionable' ? '<span class="sq">Q</span>' : item.player.status == 'doubtful' ? '<span class="sq">D</span>' : '' : ''}  ${(item.team || 'none').escapeHTML()}</span>
		</div>
		</div>

		<div class="column flex gameStatus">
		${gameStatusEl}
		<span>
		${(item.fan_pts || 0).toFixed(2)}
		</span>
		</div>

		${posStatsHTML}

		</div>`;

		groupsContent[groups[item.position]].push({html, _order: item._order});

	});

POSITIONS.groups.map(function(group, index){

	group.map(function(position){

		if (positionsFilled.indexOf(position) != -1) return;

		const pLocked = (team.user != app.auth.getUserId() || (selectedWeek <= season.last_week));

		groupsContent[groups[position]].push({
			_order: POSITIONS.types.indexOf(position),
			html: `
			<div class="item flex ${pLocked ? 'item-locked' : ''}"
			onclick="app.modules.get('team').positionClick.bind(this)();" data-position="${position}">

			<div class="column name">
			<div class="position">
			<span class="name">${position}</span>
			<span class="player-name">${position == 'DEF' ? 'Choose defense' : 'Choose player'}</span>
			<span class="team">-</span>
			</div>
			</div>

			<div class="column"></div>
			<div class="column"></div>
			<div class="column"></div>
			<div class="column"></div>

			</div>`
		});

	});

});

Object.keys(groupsContent).map(function(key){

	container.querySelector(`div.table .group-${key}`).innerHTML = groupsContent[key].sort(function(a,b){
		return a._order - b._order;
	}).map(function(item){
		return item.html;
	}).join('');

});

tippy('section#team .items .item span.sq', {
	content: 'Questionable'
});

}

async function positionClick(){

	if (this.classList.contains('item-locked')){
		return Swal.fire(
			'This position is locked!',
			'',
			'error'
			);
	}

	let week = weekSelector.value;

	if (!this.classList.contains('item-used')){
		let uri = `/leagues/${app.modules.get('league').get().identifier}/players?week=${week}&position=${this.dataset.position}`;
		return app.navigate(uri);
	}

	if (this.classList.contains('blur-out-animation')) return;

	let {isConfirmed} = await Swal.fire({
		title: `Are you sure you want to remove ${decodeURIComponent(this.dataset.name).escapeHTML()} from your roster?`,
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Yes!'
	});

	if (!isConfirmed) return;

	this.classList.add('blur-out-animation');

	try{
		const teamId = app.modules.get('league').getTeam()._id;
		await app.api.delete(`/fantasy/team/${teamId}/position/${this.dataset.id}`);
		const playerData = await app.modules.get('players')[this.dataset.position === 'DEF' ? 'defenseData' : 'playerData'].get(week);
		if (playerData) {
			playerData.clear()
		}

		// clear team cache
		const currentTeam = await teamData.get(teamId);
		if (currentTeam) {
			currentTeam.makeDirty(Number(week))
		}

		this.classList.remove('item-used');
		this.querySelector('span.team').innerText = '';

		this.querySelectorAll('.column span').forEach(function(item, index){
			if (!item.parentNode.classList.contains('column')) return;
			if (!item.parentNode.classList.contains('name')) item.parentNode.removeChild(item);
		});

		this.querySelector('.player-name').innerText = (this.dataset.position == 'DEF' ? 'Choose defense' : 'Choose player');
		delete this.dataset.id;
	} catch (e){
		Swal.fire({
			icon: 'error',
			title: `Error!`,
			text: `There was an error removing this player from your roster`
		});
		console.error(e);
		show();
	}

	this.classList.remove('blur-out-animation');

}

export default {
	show,
	positionClick,
	teamData
}
