import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import WeekSelector from '../components/week_selector';
import createCache from '../cache';

const queryString = require('query-string');
const POSITIONS = require('../constants').POSITIONS.types;
const getWeek = require('../helpers').getWeek;

const container = document.getElementById('leaderboard');
const weekSelector = new WeekSelector(container.querySelector('select[name="week"]'));
weekSelector.setOnChangeListener((value) => {
	app.modules.show('leaderboard', null, null, value);
});

container.querySelector('table tbody').addEventListener('click', function(e){
	if (!e.target.classList.contains('icon')){
		return app.navigate(`/leagues/${app.modules.get('league').getLeague().identifier}/team/${e.target.closest('tr').dataset.tid}`);
	}
});

const createMedalIcon = () => {
	return `<span class="material-icons season-first-place">workspace_premium</span>`
}

const standings = createCache({
	fetch: (league) => {
		return () => createCache({
			parameterizeKey: true,
			fetch:
			(week) => {
				return () => app.api.get(`/fantasy/league/${league}/leaderboard`, {week});
			}
		})
	}
})

async function loadData(week){
	const leagueStandings = await standings.get(currentLeague._id);
	const userId = app.auth.getUserId();
	let weekData = await leagueStandings.get(week);
	const {teams, perfect_squad, team_stats, player_of_the_week} = weekData

	Object.keys(team_stats).map(function(key){
		teams[key] = {...teams[key], ...team_stats[key]}
	});
	console.log('teams', teams)
	container.querySelector('table.seasonStandings tbody').innerHTML = '';
	container.querySelector('table.weeklyStandings tbody').innerHTML = '';
	container.querySelector('div.perfect_squad').innerHTML = '';

	const highestSeasonScore = Math.max(...Object.keys(teams).map(t => teams[t].season_points))
	const seasonLeader = Object.values(teams).find(({season_points}) => season_points === highestSeasonScore)
	container.querySelector('table.weeklyStandings tbody').innerHTML = Object.values(teams).sort(function(a,b){
		return b.points - a.points;
	}).map(function(team, i){
		const isUserTeam = userId == team.user._id;
		const isHighestScoreTeam = seasonLeader && team.user._id === seasonLeader.user._id;

		const isValidatedFirstPlace = team.week_stats_final && i === 0;
		let pq = (team.has_perfect_squad_positions || []).map(function(position){
			let name = perfect_squad[position].team;
			if (perfect_squad[position].player) name = `${perfect_squad[position].player.first_name.escapeHTML()} ${perfect_squad[position].player.last_name.escapeHTML()}`;
			return `<div class="icon icon-player icon-${position.toLowerCase()}" data-name="${name} [${position}]"></div>`;
		});
		let trophies = (team.weekly_wins || []).map(w => {
			return `<span class="material-icons weekly-winner" data-week="${w}">emoji_events</span>`
		})

		return `<tr class="${isUserTeam ? 'is-user-team' : ''} ${isValidatedFirstPlace ? 'is-first-place' : ''}"  data-tid="${team._id}">
		<td><div class="team-name"><span class="team-name__name">${team.name.escapeHTML()}</span> ${isHighestScoreTeam ? createMedalIcon() : ""} ${trophies.join('')}</div> <div class="perfect-squads">${pq.join('')}</div></td>
		<td>${(team.points || 0).toFixed(2)} ${!team.week_stats_final ? `<div class="statsNotFinal icon icon-info"></div>` : ''}</td>
		<td>${(team.season_points || 0).toFixed(2)}</td>
		</tr>`;
	}).join('');

	tippy('section#leaderboard table.weeklyStandings  .statsNotFinal', {content: `Points not final, players still pending.`});

	[...container.querySelectorAll('table.weeklyStandings tbody .icon-player')].map(function(item){
		tippy(item, {content: item.dataset.name});
	});
	[...container.querySelectorAll('table.weeklyStandings tbody .weekly-winner')].map(function(item){
		tippy(item, {content: "Winner of Week " + item.dataset.week});
	});

	container.querySelector('table.seasonStandings tbody').innerHTML = Object.values(teams).sort(function(a,b){
		return b.season_points - a.season_points;
	}).map(function(team, i){
		const isUserTeam = userId == team.user._id;

		return `<tr class="${isUserTeam ? 'is-user-team' : ''}" data-tid="${team._id}">
		<td class="team-name"><span class="team-name__name">${team.name.escapeHTML()}</span> ${i === 0 ? createMedalIcon() : ""}</td>
		<td>${(team.season_points || 0).toFixed(2)}</td>
		<td>${team.weekly_wins ? team.weekly_wins.length : 0}</td>
		</tr>`;
	}).join('');

	[...container.querySelectorAll('table tbody .season-first-place')].map(function(item){
		tippy(item, {content: "Current Season Leader"});
	});

	if (perfect_squad){

		container.querySelector('div.perfect_squad').innerHTML = POSITIONS.map(function(position){

			if (!perfect_squad[position]){
				return `<div class="margin-10 item">
				<div class="line"></div>
				<span class="name">
				TBD
				</span>

				<span class="position">${position}</span>

				</div>`;
			}

			let name = perfect_squad[position].team;
			if (perfect_squad[position].player) name = `${perfect_squad[position].player.first_name.escapeHTML()} ${perfect_squad[position].player.last_name.escapeHTML()}`;

			return `<div class="margin-10 item">
			<div class="line"></div>
			<div class="name">
			${name}
			</div>

			<div class="position nobg">${position} <span class="points">${(perfect_squad[position].fan_pts || 0).toFixed(2)} fan pts</span></div>

			</div>`;
		}).join('');
	}
}

let currentLeague;
let season;

export default {
	show: async function(leagueData, seasonData, week){
		if (seasonData) season = seasonData;

		if (leagueData) currentLeague = leagueData;

		week = getWeek(week, season);

		weekSelector.populate(seasonData, week);
		weekSelector.select(week);
		window.history.pushState(null, null, `${window.location.pathname}?week=${week}`);

		await loadData(week);
	}
}
