let currentLeague = null;
let currentTeam = null;

function selectLeague(league){
	app.setTitle(`League: ${league.name}`);
	currentLeague = league;

	currentTeam = league.teams.filter(function(team){
		return team.user._id == app.auth.getUserId();
	}).pop();

	document.body.classList.add('leagueSelected');
	if (league.user == app.auth.getUserId()) document.body.classList.add('isCommisioner');
	document.querySelector('header .title').innerText = league.name;
}

function unselectLeague(){
	document.querySelector('header .title').innerText = '';
	document.body.classList.remove('leagueSelected');
	currentLeague = null;
	currentTeam = null;
}

function ac(evt){
	document.querySelector('header .select').click();
}

document.querySelector('header .select').addEventListener('click', async function(){

	if (this.classList.contains('opened')){
		this.classList.remove('opened');
		document.querySelector('header ul.leagues').style.display = 'none';
		document.body.removeEventListener('click', ac);
		return;
	}

	document.body.addEventListener('click', ac);

	if (this.classList.contains('rotateCenter')) return;

	this.classList.add('rotateCenter');

	try{
		let data = await app.api.get('/user/me/teams');

		document.querySelector('header ul.leagues').innerHTML = data.teams.map(function(team){
			return `<li style="border-color: ${team.color || '#CCC'}"><a href="/leagues/${team.league.identifier}">${team.league.name.escapeHTML()}</a></li>`;
		}).join('');

		document.querySelector('header ul.leagues').style.display = 'block';

	} catch (e){
		console.error(e);
	}

	this.classList.add('opened');
	this.classList.remove('rotateCenter');

});

async function set(league){

	selectLeague(league);

	return currentLeague;
}

export default {
	set,

	show: async function(id){
		await load(id);
		app.modules.show('leaderboard');
	},

	get: function(){
		return currentLeague;
	},

	getTeam: function(){
		return currentTeam;
	},

	selectLeague,
	unselectLeague,

	getLeague(){
		return currentLeague;
	}
}
