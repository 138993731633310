let headers = {};

export default {

	addHeader: function(key, val){
		headers[key] = val;
	},

	removeHeader: function(key){
		delete headers[key];
	},

	get: async function(uri, qs = {}){

		let qss = '';
		if (qs && Object.keys(qs).length != 0){
			qss += '?' + Object.keys(qs).map(function(k){
				return `${k}=${encodeURIComponent(qs[k])}`;
			}).join('&');
		}

		return app.api.exec('GET', `${uri}${qss}`);
	},

	post: async function(uri, data = null){
		return app.api.exec('POST', uri, data);
	},

	put: async function(uri, data = null){
		return app.api.exec('PUT', uri, data);
	},

	delete: async function(uri){
		return app.api.exec('DELETE', uri);
	},

	exec: async function(method, uri, data = null){

		let c = {
			method: method,
			mode: 'cors',
			headers: {
				...headers,
				'Content-Type': 'application/json'
			},
			redirect: 'error',
			referrerPolicy: 'no-referrer',
		};


		if (app.auth.getToken()) c.headers.Authorization = `Bearer ${app.auth.getToken()}`;

		//fake time implementatioin

		let fakeTime = app.modules.get('fakeTime').getTime();
		if (fakeTime){
			c.headers['fake-time'] = fakeTime;
		}

		if (data) c.body = JSON.stringify(data);

		if (uri.indexOf('/api/') == 0) uri = uri.replace('/api/', '/');

		let res = await fetch(`/api${uri}`, c);

		if (res.status == 200){

			localStorage.setItem('errors', 0);

			try{
				let j = await res.json();
				return j;
			} catch (e){
				return;
			}
		}

		if (res.status && res.status == 401){

			if (uri == '/auth/logout') return;

			await app.auth.logout();
			app.navigate('/');
			return;
		}
				//logout if getting to many errors of type 400. maybe and old version of the app or something.
				if (res.status && res.status == 400){
					let errors = parseInt(localStorage.getItem('errors') || '0');
					errors++;
					if (errors > 3) {
						await app.auth.logout();
						window.location.reload();
					} else {
						localStorage.setItem('errors', errors);
					}

				}

				try{
					res.payload = await res.json();
				} catch(e){}

				throw res;

			},

		}
