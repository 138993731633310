import moment from 'moment'
import Swal from 'sweetalert2'

const container = document.getElementById('leagueMembers');

container.querySelector('input[type="submit"].invite').addEventListener('click', async function(){

	let { value: emails } = await Swal.fire({
		title: 'Invite people to your league',
		input: 'textarea',
		inputPlaceholder: 'Enter one e-mail address per line: \n\nname@domain.com\nfriend@domain.com',
		inputAttributes: {
			autocapitalize: 'off',
			autocorrect: 'off',
			autocomplete: 'off',
		}
	});

	if (!emails) return;

	emails = emails.split('\n').map(function(item){
		return item.trim();
	}).filter(function(item){
		return item != '';
	});

	if (emails.length == 0) return;

	app.modules.block();

	try{
		let data = await app.api.post(`/fantasy/league/${app.modules.get('league').getLeague()._id}/invited`, {email: emails});
		await show();
	} catch (e){
		console.error(e);
	}

	app.modules.unblock();

});

const leaveLeagueButton = container.querySelector('input[type="submit"].leave');
leaveLeagueButton.addEventListener('click', async function(){

	const isCommisioner = (app.modules.get('league').getLeague().user == app.auth.getUserId());

	let r = await Swal.fire({
		title: `Are you sure you want to leave this league?`,
		text: (isCommisioner ? 'Leaving the league as the commissioner means that this league will be permanently be deleted.' : "You won't be able to revert this!"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: (isCommisioner ? 'Yes, delete league!' : 'Yes!')
	});

	if (r.isDismissed) return;

	app.modules.block();

	try{
		await app.api.delete(`/fantasy/league/${app.modules.get('league').getLeague()._id}/member/${app.auth.getUserId()}`);
		app.navigate('/');
	} catch (e){
		console.error(e);
	}

	app.modules.unblock();

});

async function show(leagueData, season){
	app.setTitle(`Members | ${leagueData.name}`);

	container.querySelector('h1').innerText = `${leagueData.name}: ${leagueData.teams.length} members`;

	const seasonStarted = (season.next_week && season.next_week != 1);

	leaveLeagueButton.style.display = (seasonStarted ? 'none' : 'block');

	container.querySelector('span.note').innerText = `Season start: ${moment(new Date(season.start)).format('dddd, MMMM Do YYYY')}`;
	const isCommisioner = (app.modules.get('league').getLeague().user == app.auth.getUserId());
	
	// hide email fields if not commisioner
	if (!isCommisioner) {
		const emailEls = container.querySelectorAll('.league-member-email')
		if (emailEls.length) {
			emailEls.forEach(function (el) {
				el.style.display = 'none'
			})
		}
	}
		
	container.querySelector('table.members tbody').innerHTML = leagueData.teams.filter(function(team){
		return (team.status == 'active')
	}).sort(function(a,b){
		return (new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
	}).map(function(team){

		let teamName = team.name || team.user.name;

		let edit = '';
		if (!seasonStarted && team.user._id != app.auth.getUserId()){
			edit = `<div class='icon delete'><div class="material-icons" onclick="app.modules.get('leagueMembers').removeMember.bind(this)('${team.user._id}')">delete</div></div>`;
		}

		return `<tr>
		${isCommisioner ? `<td>${team.user.email}</td>` : ''}
		<td>${teamName} ${team.user._id == leagueData.user ? '(commissioner)' : ''}</td>
		<td class="edit">${edit}</td>
		</tr>`;
	}).join('');


	const priorMembers = leagueData.teams_prior && leagueData.teams_prior.filter(function(team) {
		return team.status == 'active' && !leagueData.teams.find(({user}) => user._id == team.user._id)
	});
	if (isCommisioner && priorMembers && priorMembers.length > 0) {
		container.querySelector('table.prior_members tbody').innerHTML = priorMembers.sort(function(a,b){
			return (new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
		}).map(function(team){

			let teamName = team.name || team.user.name;

			let edit = '';
			let remind = '';
			let reminded = team.reminded_status && team.reminded_status == 'reminded'
			if (!seasonStarted && team.user._id != app.auth.getUserId()){
				edit = `<div class='icon delete'><div class="material-icons" onclick="app.modules.get('leagueMembers').removeMember.bind(this)('${team.user._id}')">delete</div></div>`;
				remind = `<div class='icon remind ${reminded ? 'disabled' : ''}'><div class="material-icons" ${!reminded ? `onclick="app.modules.get('leagueMembers').remindMember.bind(this)('${team.user._id}')"` : ''}>schedule</div></div>`;
			}

			return `<tr>
			${isCommisioner ? `<td>${team.user.email}</td>` : ''}
			<td>${teamName}</td>
			<td class="edit">${edit}${remind}</td>
			</tr>`;
		}).join('');
	} else {
		container.querySelector('.prior_container').style.display = 'none'
	}

	if (isCommisioner) {
		container.querySelector('table.pending tbody').innerHTML = leagueData.teams.filter(function(team){
			return team.status == 'pending';
		}).map(function(team, index){

			return `<tr>
			${isCommisioner ? `<td>${team.user.email}</td>` : ''}
			<td>${team.user.name}</td>
			<td class="flex edit" style="justify-content: space-between">
			<div class="delete icon icon-x" onclick="app.modules.get('leagueMembers').removePending.bind(this)('${team.user._id}')"></div>
			<div class="approve icon icon-ok" onclick="app.modules.get('leagueMembers').approvePending.bind(this)('${team.user._id}')">
			</div></td>
			</tr>`;
		}).join('');
	} else {
		container.querySelector('.pending-container').style.display = 'none';
	}

	if (isCommisioner) {
		container.querySelector('table.invited tbody').innerHTML = leagueData.invited.map(function(email, index){
			return `<tr>
				${isCommisioner ? `<td>${email}</td>` : ''}
				<td class="flex edit" style="justify-content: space-between">
				<div class="icon delete" onclick="app.modules.get('leagueMembers').removeInvited.bind(this)('${email}')"><div class="material-icons">delete</div></div>
				</div></td>
			</tr>`;
		}).join('');
	} else {
		container.querySelector('.invited-container').style.display = 'none';
	}


}

async function approvePending(uid){

	if (this.classList.contains('rotateCenter')) return;

	this.classList.add('rotateCenter');

	try{
		await app.api.get(`/fantasy/league/${app.modules.get('league').getLeague()._id}/approve_member/${uid}`);
		show();
	} catch (e){

	}

	this.classList.remove('rotateCenter');

}
	
async function removeMember(uid){

	if (this.classList.contains('rotateCenter')) return;

	let {isConfirmed} = await Swal.fire({
		title: 'Are you sure you want to remove the user from this league?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Yes!'
	});

	if (!isConfirmed) return;

	this.classList.add('rotateCenter');

	try{
		await app.api.delete(`/fantasy/league/${app.modules.get('league').getLeague()._id}/member/${uid}`);
		this.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode)
	} catch (e){
		this.classList.remove('rotateCenter');
		return console.error(e);
	}

	this.classList.remove('rotateCenter');
}

async function remindMember(uid){

	if (this.classList.contains('rotateCenter')) return;

	let {isConfirmed} = await Swal.fire({
		title: 'Do you want to remind this user to rejoin this league?',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Yes!'
	});

	if (!isConfirmed) return;

	try{
		await app.api.post(`/fantasy/league/${app.modules.get('league').getLeague()._id}/member/${uid}/remind`);
	} catch (e){
		return console.error(e);
	}

}

async function removePending(uid){
	if (this.classList.contains('rotateCenter')) return;
	this.classList.add('rotateCenter');

	try{
		await app.api.delete(`/fantasy/league/${app.modules.get('league').getLeague()._id}/pending_members/${uid}`);
		this.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode)
	} catch (e){
		this.classList.remove('rotateCenter');
		return console.error(e);
	}

	this.classList.remove('rotateCenter');
}

async function removeInvited(email){

	if (this.classList.contains('rotateCenter')) return;
	this.classList.add('rotateCenter');

	try{
		await app.api.delete(`/fantasy/league/${app.modules.get('league').getLeague()._id}/invited/${encodeURIComponent(email)}`);
		this.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode)
	} catch (e){
		this.classList.remove('rotateCenter');
		return console.error(e);
	}

	this.classList.remove('rotateCenter');

}

export default {
	approvePending,
	removeMember,
	removePending,
	removeInvited,
	remindMember,
	show
}
