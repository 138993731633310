import Swal from 'sweetalert2'

const form = document.querySelector('#profile form');

form.querySelector('input[type="button"]').addEventListener('click', async function(){

	const { value: password } = await Swal.fire({
		title: 'Enter your password',
		input: 'password',
		inputPlaceholder: 'Enter your password',
		inputAttributes: {
			maxlength: 100,
			autocapitalize: 'off',
			autocorrect: 'off',
			autocomplete: 'off',
		}
	});

	if (!password) return;

	app.modules.block();

	try{
		await app.api.post('/user/me/check_password', {password: password});
		form.elements.current_password.value = password;
		app.modules.enableForm(form, true);
	} catch (e){
		console.error(e);
		app.modules.formError(form, `We couldn't verify your password...`);
		app.modules.disableForm(form);
		this.removeAttribute('disabled');
	}

	app.modules.unblock();

});

form.addEventListener('submit', async function(evt){
	evt.preventDefault();

	app.modules.block();

	let postData = {
		name: this.elements.name.value.trim(),
		email: this.elements.email.value.trim(),
		current_password: this.elements.current_password.value,
		email_notification_preferences: {
			promotional: this.elements.email_promotional.checked,
			reminders: this.elements.email_reminders.checked,
		}
	}

	let emailChangeRequest = (postData.email != this.elements.email.dataset.original);

	let password = this.elements.password.value.trim();
	if (password){

		if (password != this.elements.password2.value.trim()){
			app.modules.formError(this, 'Passwords do not match', [this.elements.password, this.elements.password2]);
			app.modules.unblock();
			return;
		}

		if (this.elements.password.value.length < this.elements.password.getAttribute('minLength')){
			app.modules.unblock();
			return app.modules.formError(this, `Password needs to be at least ${this.elements.password.getAttribute('minLength')} in length`, [this.elements.password, this.elements.password2]);
		}

		postData.password = password;
	}

	try{
		await app.api.put('/user/me', postData);
		app.modules.formErrorRemove(this);

		if (emailChangeRequest) Swal.fire(`Your data has been saved but you'll need to verify your new e-mail address. We've sent you an e-mail with instructions.`);

		if (postData.password) {
			setTimeout(function(){
				window.location.href = '/';
			}, 1500);
		}

	} catch (e){
		console.error(e);
		app.modules.formError(this, 'Could not save your data!');
	}

	app.modules.unblock();

});

export default {
	show: async function(){

		app.modules.get('league').unselectLeague();

		app.setTitle(`My profile`);

		document.querySelector('#menu').style.display = 'none';

		app.modules.disableForm(form);
		form.querySelector('input[type="button"]').removeAttribute('disabled');

		try{
			let data = await app.api.get('/user/me');
			form.elements.email.value = data.user.email;
			form.elements.email.dataset.original = data.user.email;
			form.elements.name.value = data.user.name;
			form.elements.email_reminders.checked = data.user.email_notification_preferences.reminders;
			form.elements.email_promotional.checked = data.user.email_notification_preferences.promotional;
		} catch (e){
			console.error(e);
		}

		form.querySelectorAll('input[type="password"]').forEach(function(input){
			input.value = '';
		});

	}
}
