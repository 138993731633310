/*
VARIOUS CONSTANTS USED BY BOTH !BACKEND! AND FRONTEND
*/

module.exports.COLORS = [
'#FFB41F', '#F44336', '#43A047', '#000000', '#85144b', '#001f3f', '#01FF70', '#ed98cd',
'#aefd78','#15f8d3', '#025df8', '#c57fcd', '#1a0783', '#9d2333', '#79487f', '#5835bc'
];

module.exports.STAT_ABBREVIATIONS = {
	'points_allowed': 'Pts Vs',
	'sacks': 'Sack',
	'interceptions': 'Int',
	'extra_points_made': 'XPs',
	'extra_points_attempted': 'Att',
	'field_goals_made': 'FGs',
	'passing_yards': 'Yds',
	'passing_touchdowns': 'TDs',
	'passing_interceptions': 'Ints',
	'rushing_attempts': 'Att',
	'rushing_yards': 'Yds',
	'rushing_touchdowns': 'TDs',
	'receptions': 'Rec',
	'receiving_yards': 'Yds',
	'receiving_touchdowns': 'TDs',
}

const POSITIONS = {
	types: ['QB', 'RB', 'WR', 'TE', 'DEF', 'K'],

	groups: [
	['QB', 'RB', 'WR', "TE"],
	['DEF'],
	['K']
	],

	aliases: {
		DEF: [
		'DEF', 'LB', 'DL', 'LS',
		'LEO', 'MLB', 'ILB', 'CB',
		'DE', 'OLB', 'DT', 'FS',
		'SS', 'NT', 'DB', 'S', 
		// the following are offensive positions; 
		// they don't belong here
		'FB', 'G', 'OT',
		'P', 'C', 'OL', 'T'
		],
	},

	aliases_reverse: {},
}

module.exports.STAT_PROPS = {

	/*
	data from 3rd party nfl stats provider
	if position is not defense this are individual players stats otherwise "team" stats

	Please keep in mind defense stats are also ingested so if you ever need granular for DEF the db should have it.
*/


	passing_attempts: Number,
	passing_completions: Number,
	passing_yards: Number,
	passing_completion_percentage: Number,
	passing_yards_per_attempt: Number,
	passing_yards_per_completion: Number,
	passing_touchdowns: Number,
	passing_interceptions: Number,
	passing_rating: Number,
	passing_long: Number,
	passing_sacks: Number,
	passing_sack_yards: Number,
	rushing_attempts: Number,
	rushing_yards: Number,
	rushing_yards_per_attempt: Number,
	rushing_touchdowns: Number,
	rushing_long: Number,
	receiving_targets: Number,
	receptions: Number,
	receiving_yards: Number,
	receiving_yards_per_reception: Number,
	receiving_touchdowns: Number,
	receiving_long: Number,
	fumbles: Number,
	fumbles_lost: Number,
	punt_returns: Number,
	punt_return_yards: Number,
	punt_return_yards_per_attempt: Number,
	punt_return_touchdowns: Number,
	punt_return_long: Number,
	kick_returns: Number,
	kick_return_yards: Number,
	kick_return_yards_per_attempt: Number,
	kick_return_touchdowns: Number,
	kick_return_long: Number,
	solo_tackles: Number,
	assisted_tackles: Number,
	tackles_for_loss: Number,
	sacks: Number,
	sack_yards: Number,
	quarterback_hits: Number,
	passes_defended: Number,
	fumbles_forced: Number,
	fumbles_recovered: Number,
	fumble_return_yards: Number,
	fumble_return_touchdowns: Number,
	interceptions: Number,
	interception_return_yards: Number,
	interception_return_touchdowns: Number,
	blocked_kicks: Number,
	special_teams_solo_tackles: Number,
	special_teams_assisted_tackles: Number,
	misc_solo_tackles: Number,
	misc_assisted_tackles: Number,
	punts: Number,
	punt_yards: Number,
	punt_average: Number,
	field_goals_attempted: Number,
	field_goals_made: Number,
	field_goals_longest_made: Number,
	extra_points_made: Number,
	two_points_made: Number,
	two_point_conversion_passes: Number,
	two_point_conversion_runs: Number,
	two_point_conversion_receptions: Number,
	receiving_yards_per_target: Number,
	tackles: Number,
	offensive_touchdowns: Number,
	defensive_touchdowns: Number,
	special_teams_touchdowns: Number,
	touchdowns: Number,
	fumbles_own_recoveries: Number,
	fumbles_out_of_bounds: Number,
	fumbles_recovered_touchdowns: Number,
	kick_return_fair_catches: Number,
	punt_return_fair_catches: Number,
	punt_touchbacks: Number,
	punt_inside20: Number,
	punt_net_average: Number,
	extra_points_attempted: Number,
	blocked_kick_return_touchdowns: Number,
	field_goal_return_touchdowns: Number,
	safeties: Number,
	field_goals_had_blocked: Number,
	punts_had_blocked: Number,
	extra_points_had_blocked: Number,
	extra_points_blocked_returned: Number,
	punt_long: Number,
	blocked_kick_return_yards: Number,
	field_goal_return_yards: Number,
	punt_net_yards: Number,
	special_teams_fumbles_forced: Number,
	special_teams_fumbles_recovered: Number,
	misc_fumbles_forced: Number,
	misc_fumbles_recovered: Number,
	field_goals_made0to19: Number,
	field_goals_made20to29: Number,
	field_goals_made30to39: Number,
	field_goals_made40to49: Number,
	field_goals_made50_plus: Number,
	fantasy_points_p_p_r: Number,
	fantasy_points: Number,
	fantasy_points_fan_duel: Number,
	fantasy_points_yahoo: Number,
	fantasy_points_draft_kings: Number,

	//relevant to defense only
	points_allowed: Number,
	touchdowns_scored: Number,
}


Object.keys(POSITIONS.aliases).map(function(key){
	POSITIONS.aliases[key].map(function(position){
		POSITIONS.aliases_reverse[position] = key;
	});
});

module.exports.POSITIONS = POSITIONS;

const TEAMS = {
	"ARI": {
		"abbrev": "ARI",
		"name": "Cardinals",
		"city": "Arizona",
		"full_name": "Arizona Cardinals"
	},
	"ATL": {
		"abbrev": "ATL",
		"name": "Falcons",
		"city": "Atlanta",
		"full_name": "Atlanta Falcons"
	},
	"BAL": {
		"abbrev": "BAL",
		"name": "Ravens",
		"city": "Baltimore",
		"full_name": "Baltimore Ravens"
	},
	"BUF": {
		"abbrev": "BUF",
		"name": "Bills",
		"city": "Buffalo",
		"full_name": "Buffalo Bills"
	},
	"CAR": {
		"abbrev": "CAR",
		"name": "Panthers",
		"city": "Carolina",
		"full_name": "Carolina Panthers"
	},
	"CHI": {
		"abbrev": "CHI",
		"name": "Bears",
		"city": "Chicago",
		"full_name": "Chicago Bears"
	},
	"CIN": {
		"abbrev": "CIN",
		"name": "Bengals",
		"city": "Cincinnati",
		"full_name": "Cincinnati Bengals"
	},
	"CLE": {
		"abbrev": "CLE",
		"name": "Browns",
		"city": "Cleveland",
		"full_name": "Cleveland Browns"
	},
	"DAL": {
		"abbrev": "DAL",
		"name": "Cowboys",
		"city": "Dallas",
		"full_name": "Dallas Cowboys"
	},
	"DEN": {
		"abbrev": "DEN",
		"name": "Broncos",
		"city": "Denver",
		"full_name": "Denver Broncos"
	},
	"DET": {
		"abbrev": "DET",
		"name": "Lions",
		"city": "Detroit",
		"full_name": "Detroit Lions"
	},
	"GB": {
		"abbrev": "GB",
		"name": "Packers",
		"city": "Green Bay",
		"full_name": "Green Bay Packers"
	},
	"HOU": {
		"abbrev": "HOU",
		"name": "Texans",
		"city": "Houston",
		"full_name": "Houston Texans"
	},
	"IND": {
		"abbrev": "IND",
		"name": "Colts",
		"city": "Indianapolis",
		"full_name": "Indianapolis Colts"
	},
	"JAX": {
		"abbrev": "JAX",
		"name": "Jaguars",
		"city": "Jacksonville",
		"full_name": "Jacksonville Jaguars"
	},
	"KC": {
		"abbrev": "KC",
		"name": "Chiefs",
		"city": "Kansas City",
		"full_name": "Kansas City Chiefs"
	},
	"LAC": {
		"abbrev": "LAC",
		"name": "Chargers",
		"city": "Los Angeles",
		"full_name": "Los Angeles Chargers"
	},
	"LAR": {
		"abbrev": "LAR",
		"name": "Rams",
		"city": "Los Angeles",
		"full_name": "Los Angeles Rams"
	},
	"LV": {
		"abbrev": "LV",
		"name": "Raiders",
		"city": "Las Vegas",
		"full_name": "Las Vegas Raiders"
	},
	"MIA": {
		"abbrev": "MIA",
		"name": "Dolphins",
		"city": "Miami",
		"full_name": "Miami Dolphins"
	},
	"MIN": {
		"abbrev": "MIN",
		"name": "Vikings",
		"city": "Minnesota",
		"full_name": "Minnesota Vikings"
	},
	"NE": {
		"abbrev": "NE",
		"name": "Patriots",
		"city": "New England",
		"full_name": "New England Patriots"
	},
	"NO": {
		"abbrev": "NO",
		"name": "Saints",
		"city": "New Orleans",
		"full_name": "New Orleans Saints"
	},
	"NYG": {
		"abbrev": "NYG",
		"name": "Giants",
		"city": "New York",
		"full_name": "New York Giants"
	},
	"NYJ": {
		"abbrev": "NYJ",
		"name": "Jets",
		"city": "New York",
		"full_name": "New York Jets"
	},
	"PHI": {
		"abbrev": "PHI",
		"name": "Eagles",
		"city": "Philadelphia",
		"full_name": "Philadelphia Eagles"
	},
	"PIT": {
		"abbrev": "PIT",
		"name": "Steelers",
		"city": "Pittsburgh",
		"full_name": "Pittsburgh Steelers"
	},
	"SEA": {
		"abbrev": "SEA",
		"name": "Seahawks",
		"city": "Seattle",
		"full_name": "Seattle Seahawks"
	},
	"SF": {
		"abbrev": "SF",
		"name": "49ers",
		"city": "San Francisco",
		"full_name": "San Francisco 49ers"
	},
	"TB": {
		"abbrev": "TB",
		"name": "Buccaneers",
		"city": "Tampa Bay",
		"full_name": "Tampa Bay Buccaneers"
	},
	"TEN": {
		"abbrev": "TEN",
		"name": "Titans",
		"city": "Tennessee",
		"full_name": "Tennessee Titans"
	},
	"WAS": {
		"abbrev": "WAS",
		"name": "Football Team",
		"city": "Washington",
		"full_name": "Washington Football Team"
	}
}

module.exports.TEAMS = TEAMS;