

export default {

	show: function(){
		document.querySelector(`#contentContainer`).style.display = 'none';
		document.body.classList.add('isLanding')
	},
	hide: function(){
		document.querySelector(`#contentContainer`).style.display = 'flex';
		document.body.classList.remove('isLanding')
	},
}
