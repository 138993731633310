const createCache = ({fetch, onSuccess, onError, defaultKey, parameterizeKey, expiration=3600000}) => {
    let cache = {}

    const getKey = (key) => {
        return key || defaultKey;
    }
    const getCacheKey = (key) => {
        return parameterizeKey ? new URLSearchParams(key).toString() : key;
    }
    const clear = () => {
        cache = {}
    }
    const makeDirty = (params) => {
        const key = getKey(params);
        const cacheKey = getCacheKey(key)
        const cached = cache[cacheKey]
        if (cached) {
            cached.isDirty = true;
        }
    }

    let expirationTimeout;
    const setExpiration = (params) => {
        expirationTimeout = setTimeout(() => {
            makeDirty(params)
        }, expiration)
    }

    const get = async (params) => {
        const key = getKey(params);
        const cacheKey = getCacheKey(key)
        const cached = cache[cacheKey]
        let data = cached && cached.data;
        try {
            if (!data || cached.isDirty) {
                const fetcher = fetch(key)
                const newData = await fetcher();
                if (onSuccess) {
                    onSuccess(newData)
                }
                cache[cacheKey] = {data: newData, isDirty: false};
                data = newData;
                // set expiration
                if (expiration) {
                    clearTimeout(expirationTimeout)
                }
                setExpiration(params)
            }

        } catch (error) {
            if (onError) {
                onError(error)
            }
        }
        return data

    }

    return {
        get,
        clear,
        makeDirty,
    }
}
export default createCache