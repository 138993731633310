import '../css/cssreset-min.css'
import '../css/index.scss'
import * as ROUTER from'./router'
import APP from './app'
require('./utils');

window.app = APP;
ROUTER.init(window.app);
window.app.navigate = async function(uri){
	if (uri){
		let h = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
		if (uri.indexOf(h) == 0) uri = uri.replace(h, '');
	}
	return ROUTER.navigate(APP, uri);
}

document.addEventListener("DOMContentLoaded", window.app.init.bind({app: window.app}));
