import moment from 'moment';
const queryString = require('query-string');

const table = document.querySelector('#users table tbody');
const input = document.querySelector('#users input[type="search"]');
const nextButton = document.querySelector('#users a.next');
const prevButton = document.querySelector('#users a.prev');

export default {
	show: async function(){

		app.modules.get('league').unselectLeague();

		app.setTitle(`Users | admin`);

		let qs = queryString.parse(window.location.search);
		if (qs.q && input.value != qs.q) input.value = qs.q;

		return doLoad('/api/user', {
			q: input.value,
			page: qs.page
		});
	}
}

input.addEventListener('search', function(){
	app.navigate('/admin/users?q='+encodeURIComponent(this.value.trim()));
});

async function doLoad(uri, pars = null){

	table.innerHTML = '';
	prevButton.style.display = 'none';
	nextButton.style.display = 'none';

	let data = await app.api.get(uri, pars);

	data.users.map(function(user){
		let row = table.insertRow();
		let cell = row.insertCell();
		cell.innerText = user.name;
		cell = row.insertCell();
		cell.innerText = user.email;
		cell = row.insertCell();
		cell.innerText = user.status;
		cell = row.insertCell();
		cell.innerText = moment(new Date(user.created_at)).local().format('YY/M/D, HH:mm');
	});

	let qs = queryString.parse(window.location.search);
	if (qs.page) qs.page = parseInt(qs.page);

	if (data.nextPage){
		qs.page = (qs.page || 1) + 1;
		nextButton.href = window.location.pathname + '?' + queryString.stringify(qs);
		nextButton.style.display = 'block';
	}

	if (data.prevPage){
		qs.page = (qs.page || 1) - 1;
		prevButton.href = window.location.pathname + '?' + queryString.stringify(qs);
		prevButton.style.display = 'block';
	}


}
