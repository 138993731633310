import Swal from 'sweetalert2'

const section = document.getElementById('letsgetyoustarted');
const formJoin = section.querySelector('.tab-join form');
const formCreate = section.querySelector('.tab-create form');

section.querySelector('.create input[type="button"]').onclick = function(){
	show('create');
}

section.querySelector('.join input[type="button"]').onclick = function(){
	show('join');
}

let back = null;

section.querySelectorAll('form input[type="button"]').forEach(function(el){
	el.onclick = function(){

		localStorage.removeItem('wanted_to_join_league');

		if (back){
			app.navigate(back);
			back = null;
			return;
		}

		show();

	}
});

async function show(tab = 'welcome', pars = null){

	app.setTitle(`Let's get started`);

	if (pars && pars.back) back = pars.back;

	if (tab == 'welcome' && pars && pars.ignore_if_has_leagues){
		let data = await app.api.get('/user/me/teams');
		if (data.teams.length > 0) return app.modules.show('leagues', data);
	}

	let user = (await app.auth.getUser()).user;

	section.querySelectorAll('input[name="team_name"]').forEach(function(item){
		if (item.value.trim() == '') item.value = user.name;
	});

	section.querySelectorAll(`.tab-${tab} input[type="text"]`).forEach(function(item){
		item.value = '';
		item.blur();
	});

	if (tab == 'join' && pars && pars.id && pars.password){
		formJoin.elements.identifier.value = pars.id;
		formJoin.elements.password.value = pars.password;
	}

	document.querySelector('#menu').style.display = 'none';

	section.querySelectorAll('div.tab').forEach(function(item){
		item.style.display = 'none';
	});

	section.querySelector(`div.tab-${tab}`).style.display = '';
}


async function join(evt){
	if (evt) evt.preventDefault();

	app.modules.block();

	const LID = this.elements.identifier.value.toUpperCase().trim();

	try{
		let res = await app.api.post(`/fantasy/league/${LID}/join`, {
			password: this.elements.password.value, 
			team_name: this.elements.team_name.value
		});

		switch(res.status){
			case 'joined':
			app.navigate(`/leagues/${res.league._id}`);
			break;

			case 'pending_approval':
			let msg = [];
			msg.push('Membership to this league requires the Commissioner\'s approval');
			msg.push('Once you get approved you\'ll be able to create your team.');
			msg.push('The commisioner has been notified!');

			Swal.fire(msg.join('\n'));
			app.modules.formClear(this);
			break;
		}

	} catch (e){

		console.error(e);

		switch(e.status){

			case 409:

			if (!e.payload) e.payload = {};

			switch(e.payload.error){
				case 'already_joined': app.navigate(`/leagues/${LID}`); break;
				case 'removed': 
				app.modules.formError(this, `You were removed from this league! Ask the Commisioner to invite you again in order to be able to join this league again!`);
				break;

				default: app.modules.formError(this, `Error joining league!`); break;
			}

			break;

			case 400:

			if (e.payload && e.payload.error.name.properties.error == 'invalid_team_name'){

					//ask for a specific team name as this team is already in use in the league.

					const { value: name } = await Swal.fire({
						title: 'Choose a name for your team in this league',
						input: 'text',
						inputPlaceholder: `Your team's name`,
						inputAttributes: {
							maxlength: 20,
							autocapitalize: 'off',
							autocorrect: 'off',
							autocomplete: 'off',
						}
					});

					if (name){
						this.elements.team_name.value = name;
						app.modules.formErrorRemove(this);
						return join.bind(this)();
					}

				} else {
					app.modules.formError(this, `Error joining league!`);
				}

				break;

				case 404:
				app.modules.formError(this, `We couldn't find this league. Please make sure the league's ID is valid`, [this.elements.identifier]);
				break;

				case 403:
				app.modules.formError(this, `Invalid password`, [this.elements.password]);
				break;

				default:
				app.modules.formError(this, `Error joining league!`);
				break;
			}

		}

		app.modules.unblock();

	}

	async function create(evt){
		if (evt) evt.preventDefault();

		if (this.elements.password.value.trim() != this.elements.password2.value.trim()){
			app.modules.unblock();
			return app.modules.formError(this, 'Passwords do not match', [this.elements.password, this.elements.password2])
		}

		if (this.elements.password.value.length < this.elements.password.getAttribute('minLength')){
			app.modules.unblock();
			return app.modules.formError(this, `Password needs to be at least ${this.elements.password.getAttribute('minLength')} in length`, [this.elements.password, this.elements.password2]);
		}

		try{

			let res = await app.api.post(this.getAttribute('action'), {
				name: this.elements.name.value.trim(),
				password: this.elements.password.value.trim(),
				team_name: this.elements.team_name.value.trim()
			});

		} catch (e){
			console.error(e);
			app.modules.unblock();

			switch(e.status){
				case 400:

				if (e.payload.error.name){
					if (e.payload.error.name.properties.error == 'invalid_league_name'){
						return app.modules.formError(this, 'League name invalid or in use. Please choose another name for your league.', [this.elements.name]);
					} 

					if (e.payload.error.name.properties.error == 'invalid_team_name'){

					//ask for a specific team name as this team is already in use in the league.

					const { value: name } = await Swal.fire({
						title: 'Choose a name for your team in this league',
						input: 'text',
						inputPlaceholder: `Your team's name`,
						inputAttributes: {
							maxlength: 20,
							autocapitalize: 'off',
							autocorrect: 'off',
							autocomplete: 'off',
						}
					});

					if (name){
						this.elements.team_name.value = name;
						app.modules.formErrorRemove(this);
						create.bind(this)();
					}

				}

			}

			break;
		}

		return app.modules.formError(this, 'An error has occurred while trying to create your league.')
	}

	app.navigate('/leagues');
	app.modules.unblock();
}

formJoin.addEventListener('submit', join);
formCreate.addEventListener('submit', create);

export default {
	show: show
}
