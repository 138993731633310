import Swal from 'sweetalert2'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import WeekSelector from '../components/week_selector';
import Modal from '../components/modal';
import moment from 'moment';
import { findGameFromSeasonData, getGameStatusString, getGameScoreString, getVsString } from '../helpers';
import createCache from '../cache';

const queryString = require('query-string');
const container = document.getElementById('players');
const POSITIONS = require('../constants').POSITIONS.types;
const TEAMS = require('../constants').TEAMS;
const STAT_ABBREVIATIONS = require('../constants').STAT_ABBREVIATIONS;
const getWeek = require('../helpers').getWeek;

let searchInput = container.querySelector('input[name="search"]');
searchInput.addEventListener(searchInput.onsearch ? 'search' : 'change', search);

container.querySelector('select[name="position"]').addEventListener('change', function () {
	searchInput.value = '';
	search();
});

const profileModal = new Modal(container.querySelector('.profile-modal'));

const weekSelector = new WeekSelector(container.querySelector('select[name="week"]'));
weekSelector.setOnChangeListener(() => {
	search();
});

container.querySelector('select[name="position"]').innerHTML = POSITIONS.map(function (item) {
	return `<option value="${item}">${item}</option>`;
}).join('');

const handleItemSort = (target) => {
	const sortDataKey = target.dataset.sort;
	let doSort;
	if (target.classList.contains('sorted')) {
		target.classList.remove('sorted');
		doSort = false;
	} else {
		target.classList.add('sorted');
		doSort = true;
	}

	let sorted = [...container.querySelectorAll('.items .item')].sort(function (a, b) {
		if (doSort) {
			const bProjection = b.classList.contains('stats-projected') ? 1 : 0;
			const aProjection = a.classList.contains('stats-projected') ? 1 : 0;
			return (bProjection - aProjection) || b.dataset[sortDataKey] - a.dataset[sortDataKey]
		};
		return a.dataset.sortname.localeCompare(b.dataset.sortname);
	}).map(function (item) {
		return item.outerHTML;
	});

	container.querySelector('.items').innerHTML = sorted.join('');
}

[...container.querySelectorAll('span.sortBy')].map(function (item) {
	item.addEventListener('click', function () {
		handleItemSort(this)
	});
});

async function showProfile(e) {
	const target = e.target.closest('.item');
	const { player, position, team } = target.dataset || {};
	app.modules.block();

	let q = {
		id: position === 'DEF' ? team : player,
		league: app.modules.get('league').getLeague()._id,
		position,
		team
	}

	let posStats;
	switch (position) {
		case 'DEF':
			posStats = ['points_allowed', 'sacks', 'interceptions'];
			break;

		case 'K':
			posStats = ['extra_points_made', 'extra_points_attempted', 'field_goals_made'];
			break;

		case 'QB':
			posStats = ['passing_yards', 'passing_touchdowns', 'passing_interceptions'];
			break;

		case 'RB':
			posStats = ['rushing_attempts', 'rushing_yards', 'rushing_touchdowns'];
			break;

		default:
			posStats = ['receptions', 'receiving_yards', 'receiving_touchdowns'];
			break;
	}

	q.get_stats = posStats.join(',');

	try {
		profileModal.show();
		profileModal.onClose = () => {
			target.focus();
		}

		const profileData = await app.api.get('/player/profile', q);
		if (!profileData) {
			throw new Error('No player data found');
		}
		const { player, totals = {}, weekData = [] } = profileData;

		const statCols = posStats.map((stat => `<span>${STAT_ABBREVIATIONS[stat] || ''}</span>`)).join('');

		const rows = weekData.map(({ isByeWeek, week, weekStats, gameData, selections } = {}, i) => {
			if (isByeWeek) {
				return `
					<div class="item">
						<div class="column flex bye-week">
							<span class="sm">${week}</span>
							<span>--- BYE WEEK ---</span>
						</div>
					</div>
				`
			}
			const {
				is_projection=false,
				stats = {}} = (weekStats || {});
			const {
				gameState={},
				opp=""
			} = (gameData || {});
			const {
				selectionsOfPlayer = "",
				selectionPercentage = "",
				leagueSelections = [] } = selections;
			const myTeam = app.modules.get('league').getTeam()._id;
			const hasSelectedPlayer = leagueSelections.find(({ _id }) => _id == myTeam);
			return `
				<div class="item flex${is_projection || Number(season.current_week) < i + 1 ? ' is-projection' : ''}${hasSelectedPlayer ? ' has-selected' : ''}">
					<div class="column flex">
						<span class="sm">${week}</span>
						<span>${opp}</span>
						<span>${gameState}</span>
					</div>
					<div class="column flex">
						<span>${stats.fan_pts || '-'}${is_projection ? '*' : ''}</span>
						${posStats.map((stat) => `<span>${stats[stat] || '-'}</span>`).join('')}
					</div>
					<div class="column flex selections">
						${selectionsOfPlayer && selectionPercentage
					? `
								<span>${selectionsOfPlayer}</span>
								<span>${selectionPercentage}</span>
								<span class="team-selections">${leagueSelections.length === 0
						? '---'
						: leagueSelections.map(({ name, color }) => {
							return `
												<div class="helmet-icon" data-name="${name}" style="border-color: ${color}">
													<svg width="27" height="24" viewBox="0 0 27 24" fill="${color}" xmlns="http://www.w3.org/2000/svg">
														<path d="M18.3297 13.2172L17.5119 10.7854C17.5841 10.7373 17.6563 10.6922 17.7344 10.6471C19.0002 10.1511 24.9503 7.80346 24.9503 7.43974C24.9338 7.22569 24.8414 7.02454 24.6899 6.87247C24.5383 6.72039 24.3375 6.62735 24.1234 6.6101C23.8069 6.67506 23.4955 6.76246 23.1914 6.87162C22.4999 4.74641 18.5462 0 12.5511 0C5.93658 0 0.142883 4.95382 0.142883 11.5669C0.142883 18.18 5.93658 23.1338 6.75738 23.1338C7.57818 23.1338 8.41401 21.4866 10.0646 21.4866C11.7152 21.4866 12.5421 22.3162 15.0225 22.3162C16.6791 22.3162 17.4999 20.666 17.4999 19.0097C17.5 18.7269 17.4512 18.4462 17.3556 18.18C17.9389 18.18 18.3297 18.18 18.3297 18.18C18.3297 18.18 19.4572 20.4345 19.9864 21.4866C20.8072 23.1429 21.634 23.1429 24.9412 23.1429C25.765 23.1429 26.5918 19.8363 26.5918 14.0528C26.6009 13.635 22.4638 13.2172 18.3297 13.2172ZM11.7152 16.5237C11.3891 16.5231 11.0704 16.4261 10.7993 16.2449C10.5281 16.0636 10.3166 15.8063 10.1914 15.5052C10.0662 15.2041 10.0329 14.8727 10.0956 14.5527C10.1583 14.2327 10.3142 13.9383 10.5438 13.7067C10.7734 13.4751 11.0663 13.3165 11.3858 13.251C11.7053 13.1854 12.0371 13.2157 12.3394 13.3382C12.6416 13.4607 12.901 13.6698 13.0847 13.9392C13.2684 14.2086 13.3683 14.5264 13.3719 14.8524C13.2035 15.2642 13.0532 15.655 12.9269 16.0007C12.7726 16.1659 12.5859 16.2976 12.3785 16.3876C12.1711 16.4776 11.9474 16.5239 11.7213 16.5237H11.7152ZM16.5258 16.4576C16.0545 15.4434 15.82 14.3353 15.8403 13.2172C15.8416 13.0137 15.871 12.8115 15.9275 12.616L17.4909 16.5237C17.1712 16.5197 16.852 16.4976 16.5348 16.4576H16.5258ZM24.1144 21.4866C24.1144 21.4866 22.0098 21.4024 21.634 20.666C21.3333 20.0648 20.9244 19.1269 20.5215 18.189H24.824C24.6045 20.5006 24.1144 21.4866 24.1144 21.4866ZM24.9292 16.5328H19.818C19.4362 15.5769 19.1566 14.8735 19.1566 14.8735C22.4638 14.8735 24.9412 14.8735 24.9412 15.6941C24.9412 15.9887 24.9352 16.2622 24.9292 16.5237V16.5328Z"/>
													</svg>
												</div>
											`
						}).join('')}
								</span>
							`
					: '<span>---</span>'
				}
					</div>
				</div>
			`
		}).join('')
		const html = `
			<div id="player-profile">
				<h1 class="textLeft">${position === 'DEF' ? TEAMS[team].full_name : player.first_name + ' ' + player.last_name}</h1>
				${position !== 'DEF' ? `<p class="player-profile-team" style="margin-bottom: 1em">${position} - ${TEAMS[team].full_name}</p>` : ''}
				<div style="margin-bottom: 1em">
					<div class="smallBox">
						<span class="title">Pos Rank</span>
						<span class="value">${(totals && totals.rank) || '---'}</span>
					</div>
					<div class="smallBox">
						<span class="title">Fan Pts</span>
						<span class="value">${(totals && totals.fan_pts && totals.fan_pts.toFixed(2)) || '---'}</span>
					</div>
					${posStats.map((stat) => `
						<div class="smallBox">
							<span class="title">${STAT_ABBREVIATIONS[stat] || ''}</span>
							<span class="value">${(totals && totals[stat]) || '---'}</span>
						</div>
					`).join('')}
				</div>
				<div class="table">
					<div class="flex header">
						<span class="column">Matchup</span>
						<span class="column">Stats</span>
						<span class="column">Selections</span>
					</div>
					<div class="flex header subheader">
						<div class="column flex">
							<span class="sm">Wk</span>
							<span>Opp</span>
							<span>Status</span>
						</div>
						<div class="column flex">
							<span>Fan Pts</span>
							${statCols}
						</div>
						<div class="column flex selections">
							<span class='selections-total'>Total</span>
							<span class='selections-percentage' data-pos="${position}">%</span>
							<span class="team-selections team-selections--header">League Selections</span>
						</div>
					</div>
					<div class="items">
						${rows}
					</div>
				</div>
			</div>
		`

		profileModal.render(html);

		tippy('#player-profile .helmet-icon', {
			content: (reference) => `Selected by ${reference.getAttribute('data-name')}`,
		});
		tippy('#player-profile .selections-total', {
			content: 'Total selections across platform',
		});
		tippy('#player-profile .selections-percentage', {
			content: (reference) => `% of all ${reference.getAttribute('data-pos')} chosen this week`,
		});
		tippy('#player-profile .team-selections--header', {
			content: 'Teams in your league who selected this player',
		});
	} catch (e) {
		app.modules.show('error', 'Error', ['Error loading data'], window.location.href);
		profileModal.hide();
		console.error(e);
	}

	app.modules.unblock();
}



const playerData = createCache({
	fetch: (week) => {
		return () => createCache({
			parameterizeKey: true,
			fetch:
			(q) => {
				return () => app.api.get('/player', {...q, week});
			}
		})
	}
})

const defenseData = createCache({
	fetch: (week) => {
		return () => createCache({
			parameterizeKey: true,
			fetch:
			(q) => {
				return () => app.api.get('/defense', {...q, week});
			}
		})
	}
})

const getQueryParams = () => {
	const text = container.querySelector('input[name="search"]').value.trim();
	const position = container.querySelector('select[name="position"]').value;
	let q = {
		fantasy_team: app.modules.get('league').getTeam()._id,
	}

	switch (position) {
		case 'DEF':
			q.get_stats = ['points_allowed', 'sacks', 'extra_points_made', 'interceptions',
				'blocked_kicks', 'punt_return_touchdowns', 'blocked_kicks'].join(',');
			break;

		case 'K':
			q.get_stats = ['extra_points_made', 'extra_points_attempted', 'field_goals_made0to19', 'field_goals_made20to29',
				'field_goals_made30to39', 'field_goals_made40to49', 'field_goals_made50_plus'
			];
			break;

		default:
			q.get_stats = ['passing_yards', 'passing_touchdowns', 'passing_interceptions', 'rushing_attempts',
				'rushing_yards', 'rushing_touchdowns', 'rushing_attempts', 'receptions', 'receiving_yards', 'receiving_touchdowns'].join(',');
			break;
	}

	if (text) q.q = text;
	if (position) q.positions = position;
	return q
}
async function search(firstLoad = false) {

	app.modules.block();

	[...container.querySelectorAll('span.sortBy')].map(function (item) {
		item.classList.remove('sorted');
	});

	const week = weekSelector.value;
	const q = getQueryParams(app)
	console.log('q', q)
	try {
		const cachedData = await (q.positions == 'DEF' ? defenseData : playerData).get(week)
		let entries = await cachedData.get(q)
		console.log('data found', entries)
		if (firstLoad === true) {
			app.modules.unblock();
			return entries;
		}

		window.history.pushState(null, null, `${window.location.pathname}?week=${week}&position=${q.positions}`);
		show(leagueDataCache, entries, null, q.q);

	} catch (e) {
		app.modules.show('error', 'Error', ['Error loading data'], window.location.href);
		console.error(e);
	}

	app.modules.unblock();
}

let leagueDataCache = null;
let returnToTeamOnceSaved = false;

let season;
async function show(leagueData, entries = null, setSeason = null, searchText = null) {

	container.querySelector('input[name="search"]').value = searchText || '';

	returnToTeamOnceSaved = false;
	const qs = queryString.parse(window.location.search);

	if (leagueData) {
		leagueDataCache = leagueData;
	} else {
		leagueData = leagueDataCache;
	}

	app.setTitle(`Players | ${leagueData.name}`);
	let week = qs.week;
	if (setSeason) {
		season = setSeason;
		week = getWeek(week, season)
		weekSelector.populate(season, week);
	}

	weekSelector.select(week)

	if (qs.week) {
		returnToTeamOnceSaved = true;
	}

	if (!entries) {
		if (qs.position) {
			app.modules.selectOption(container.querySelector('select[name="position"]'), qs.position);
		} else {
			container.querySelector('select[name="position"]').selectedIndex = 0;
		}
	}

	const position = container.querySelector('select[name="position"]').value;

	[...container.classList].map(function (cl) {
		if (cl.indexOf('position-selected') == -1) return;
		container.classList.remove(cl);
	});

	container.classList.add(`position-selected-${position}`);

	if (!entries) entries = await search(true);

	container.querySelector('.table').scrollLeft = 0;

	container.querySelector('.table div.items').innerHTML = entries[position == 'DEF' ? 'teams' : 'players']
		.map(function (item, index) {

			if (position == 'DEF') item.team = item.name;

			if (item.used === undefined) item.used = false;

			let bv;
			if (item.used !== false) bv = `Week ${item.used.week}`;
			if (!bv && item.team_played_this_week) bv = 'Locked';
			if (!bv && item.status == 'injured') bv = 'Injured';
			let selectButton = `<input type="button" value="${bv || 'Select'}">`;

			if (!item.position_stats) item.position_stats = { stats: {} };
			let { stats = {}, is_projection } = item.position_stats;
			let fan_pts = item.position_stats.fan_pts || stats.fan_pts || 0;

			let statsHtml;

			switch (position) {

				case 'DEF':

					statsHtml = `
			<div class="column flex">
			<span class="v">${stats.points_allowed || 0}</span>
			<span class="v">${stats.sacks || 0}</span>
			</div>

			<div class="column flex">
			<span class="v">${stats.interceptions || 0}</span>
			<span class="v">${stats.fumbles_recovered || 0}</span>
			</div>

			<div class="column flex">

			<span class="v">${stats.punt_return_touchdowns || 0}</span>
			<span class="v">${stats.blocked_kicks || 0}</span>

			</div>`;


					break;

				case 'K':

					statsHtml = `
			<div class="column flex">
			<span class="v">${stats.extra_points_made || 0}</span>
			<span class="v">${stats.extra_points_attempted || 0}</span>
			</div>

			<div class="column flex">
			<span class="v">${stats.field_goals_made0to19 || 0}</span>
			<span class="v">${stats.field_goals_made20to29 || 0}</span>
			</div>

			<div class="column flex">

			<span class="v">${stats.field_goals_made30to39 || 0}</span>
			<span class="v">${stats.field_goals_made40to49 || 0}</span>
			<span class="v">${stats.field_goals_made50_plus || 0}</span>

			</div>
			`;

					break;

				default:
					statsHtml = `
			<div class="column flex">

			<span class="v">${stats.passing_yards || '0'}</span>
			<span class="v">${stats.passing_touchdowns || '0'}</span>
			<span class="v">${stats.passing_interceptions || '0'}</span>

			</div>

			<div class="column flex">

			<span class="v">${stats.rushing_attempts || '0'}</span>
			<span class="v">${stats.rushing_yards || '0'}</span>
			<span class="v">${stats.rushing_touchdowns || '0'}</span>
			</div>

			<div class="column flex">

			<span class="v">${stats.receiving_targets || '0'} </span>
			<span class="v">${stats.receptions || '0'}</span>
			<span class="v">${stats.receiving_yards || '0'}</span>
			<span class="v">${stats.receiving_touchdowns || '0'}</span>

			</div>`;
					break;
			}

			const week = weekSelector.value;

			let name = TEAMS[item.name] ? TEAMS[item.name].full_name : item.name;
			if (position != 'DEF') name = `${item.first_name} ${item.last_name}`;

			let sortName = position != 'DEF' ? `${item.last_name}, ${item.first_name}` : name;

			let is;
			if (item.used !== false) is = 'item-used';
			if (!is && item.team_played_this_week) is = 'item-played';
			if (!is && item.status == 'injured') is = 'item-injured';

			const vsGame = findGameFromSeasonData(week, item.team, season);
			const gameStatus = getGameStatusString(item.team, vsGame)
			const gameScore = getGameScoreString(item.team, vsGame);
			const vs = getVsString(item.team, vsGame);

			const gameStatusEl = `<span class="gameStatus">${gameStatus}<br />${gameScore ? gameScore + ' ' : ''}${vs}</span>`;

			return `
		<div class="item flex ${is || ''} ${is ? 'item-locked' : ''} ${is_projection ? 'stats-projected' : 'stats-real'}"
		data-index="${index}"
		data-name="${name.escapeHTML()}"
		data-sortname="${sortName.escapeHTML()}"
		data-position="${position}"
		data-team="${item.team}"
		data-fanpts="${fan_pts}"
		tabindex="0"
		${position == 'DEF' ? '' : 'data-player="' + item._id + '"'}
		>

		<div class="column name">
		<div class="position">
		<span class="name">${position}</span>
		<span class="player-name">${name}</span>
		<div class="team">${item.status == 'questionable' ? '<span class="sq">Q</span>' : item.status == 'doubtful' ? '<span class="sq">D</span>' : ''} ${item.team}</div>
		</div>
		${selectButton}
		</div>

		<div class="column flex">
		<div class="columnHeader">
		<span>Fantasy</span>
		Vs, Fan pts
		</div>
		${gameStatusEl}
		<span class="v">${fan_pts.toFixed(2)}${is_projection ? '*' : ''}</span>
		</div>

		${statsHtml}

		</div>

		`;
		}).join('');

	const defaultSortItem = container.querySelector('[data-sort="fanpts"]');

	if (defaultSortItem) {
		handleItemSort(defaultSortItem)
	}

	tippy('section#players .items .stats-projected span.v', {
		content: `Projected stat for week ${weekSelector.value}`,
		delay: 500,
	});

	tippy('section#players .items .stats-real span.v', {
		content: `Total stats`,
		delay: 500,
	});

	tippy('section#players .items .item-used input[type="button"]', {
		content: `Player already selected.`
	});

	tippy('section#players .items .item-played input[type="button"]', {
		content: `Player has played this week and is locked for the week.`
	});

	tippy('section#players .items .item span.sq', {
		content: 'Questionable'
	});


}

//select bind
container.querySelector('div.table .items').addEventListener('click', select);
container.querySelector('div.table .items').addEventListener('keydown', handleKeySelect);
function handleKeySelect(e) {
	if (e.key === "Enter") {
		requestAnimationFrame(() => select(e));
	}
}

async function select(e) {
	// if the user selected anything but the "select" button
	if (e.target.type != 'button') {
		showProfile(e)
	} else {

		let button = e.target;
		let item = button.closest('.item');

		if (item.classList.contains('item-locked') || button.classList.contains('flip-animation')) return;

		let { position, name, team, player } = item.dataset;

		let week = weekSelector.value;

		let { isConfirmed } = await Swal.fire({
			title: `Do you want to add ${name.escapeHTML()} as ${position} for Week ${week}?`,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes!'
		});

		if (!isConfirmed) return;

		button.classList.add('flip-animation');

		try {

			const teamId = app.modules.get('league').getTeam()._id;
			await app.api.post(`/api/fantasy/team/${teamId}/position`, {
				player,
				team,
				week,
				position
			});

			const {teamData} = app.modules.get('team');
			const currentTeam = teamData && await teamData.get(teamId);
			if (currentTeam) {
				currentTeam.makeDirty(week)
			}

			const cachedData = await (position === 'DEF' ? defenseData : playerData).get(week)
			if (cachedData) {
				await cachedData.makeDirty(getQueryParams())
			}

			item.classList.add('item-locked');
			item.classList.add('item-used');
			item.querySelector('input').value = `Week ${week}`;

			if (returnToTeamOnceSaved) return app.navigate(`/leagues/${app.modules.get('league').get().identifier}/team?week=${week}`);

		} catch (e) {

			console.error(e);

			let errorType = (e && e.payload && e.payload.error ? e.payload.error : 'other');
			console.log(e)
			switch (errorType) {

				case 'time_advanced':
					Swal.fire({
						icon: 'error',
						title: `Error adding player`,
						text: `You can only draft 3 weeks in advance!`
					});
					break;

				case 'time':
					Swal.fire({
						icon: 'error',
						title: `Error adding player`,
						text: `Week ${week} has passed and player selection is locked for this week!`
					});
					break;

				case 'team_full':
					Swal.fire({
						icon: 'error',
						title: `Your roster for the week is full!`,
						text: `You can't add any more positions for Week ${week}`
					});
					break;

				case 'position_filled':
					Swal.fire({
						icon: 'error',
						title: `Position already filled!`,
						text: `This position is already filled for Week ${week}!`
					});
					break;

				case 'already_played':

					Swal.fire({
						icon: 'error',
						title: `Player is locked!`,
						text: `This player's team has already been active in Week ${week}!`
					});

					break;

				default:
					Swal.fire({
						icon: 'error',
						title: `Oops... an error occurred while saving your selection!`,
						text: 'Something went wrong!'
					});
					break;
			}


			search();

		}

		this.classList.remove('flip-animation');
	}
}

export default {
	show,
	select,
	playerData,
	defenseData
}
