import Swal from 'sweetalert2'

function setSession(val){
	if (val === null || val === undefined) return localStorage.clear();
	localStorage.setItem('auth_session', JSON.stringify(val));
}

function getSession(){
	let v = localStorage.getItem('auth_session');
	if (v && v != '') return JSON.parse(v);
	return;
}

async function processLoginResult(result){
	if (result.user.isAdmin){
		document.body.classList.add('isAdmin');
	} else {
		document.body.classList.remove('isAdmin');
	}

	if (result.allow_fake_time) app.modules.get('fakeTime').enable();

	setSession(result);
	document.body.classList.add('isLoggedIn');
}

export default {
	login: async function(email, password){
		console.log('logging in')
		let result = await app.api.post('/api/auth', {email, password});
		await processLoginResult(result);
	},

	activateEmail: async function(tokenId, tokenValue){
		let result = await app.api.get(`/email_activate/${tokenId}/${tokenValue}`);
		await processLoginResult(result);
	},


	validateEmail: async function(tokenId, tokenValue){
		await app.api.get(`/email_validate/${tokenId}/${tokenValue}`);
	},

	logout: async function(promptConfirm = false){

		if (promptConfirm){
			let {isConfirmed} = await Swal.fire({
				title: `Are you sure you want to logout?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, logout!'
			});

			if (!isConfirmed) return;
		}

		app.modules.block();

		try{
			await app.api.get('/auth/logout');
		} catch(e){}

		app.modules.unblock();

		setSession(null);

		window.location.reload();
	},

	getUserId: function(){
		let session = getSession();
		if (session) return session.user._id;
	},

	getUser: async function(){
		if (!this.getUserId()) return false;
		return await app.api.get('/user/me');
	},

	getToken: function(){
		let session = getSession();
		if (session) return session.token;
	}

}
