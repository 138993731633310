const container = document.getElementById('leagues');

export default {
	show: async function(){

		document.querySelector('#menu').style.display = 'none';

		app.setTitle(`My leagues`);

		try{

			const {teams, standings, season} = await app.api.get('/user/me/teams?with_details=1');

			if (teams.length == 0){
				app.modules.show('letsgetyoustarted');
				app.modules.unblock();
			}

			container.querySelector('.items').innerHTML = teams.sort(function(a,b){
				return (new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
			}).map(function(team){

				let overallStanding = 'TBD';
				let weeklyStanding = 'TBD';
				let weeklyPts = 0;
				let overallPts = 0;

				let tp = {};

				let displayWeek = (season.current_week || season.last_week);
				let cd = new Date().getDay();
				if (!season.current_week && cd > 2 && cd < 5) displayWeek = false;

				if (standings && standings[team.league.identifier]){

					for (let week in standings[team.league.identifier]){

						let cw = standings[team.league.identifier][week];

						cw.teams.map(function(ts, index){

							if (!tp[ts.team]) tp[ts.team] = 0;
							tp[ts.team] += ts.points;

							if (ts.team != team._id) return;

							//this week standing for this team
							if (parseInt(week) == displayWeek){
								weeklyStanding = (parseInt(index) + 1).nth();
								weeklyPts = ts.points;
							}

						});


					}

				}

				let tps = Object.keys(tp).map(function(key){
					return { team: key, points: tp[key] }
				}).sort(function(a,b){
					return b.points - a.points;
				});

				for (let i in tps){
					if (tps[i].team == team._id){
						overallStanding = (parseInt(i) + 1).nth();
						overallPts = tps[i].points;
						break;
					}
				}

				return `<div class="box" style="border-color: ${team.color || '#CCC'}" onclick="app.navigate('/leagues/${team.league.identifier}')">
				<h2 class="textLeft">${team.league.name.escapeHTML()}</h2>
				<span class="teamCount">${team.league.teams.length == 1 ? '1 team' : team.league.teams.length + ' teams'}</span>
				<div class="flex">

				<div class="margin-10 total">
				<div class="icon material-icons">bar_chart</div>
				<span class="title">Overall standing</span>
				<span class="position">${overallStanding}</place>
				<span class="pts">${overallPts.toFixed(2)} pts</span>
				</div>

				<div class="margin-10 weekly">
				<div class="icon material-icons">bar_chart</div>
				<span class="title">Weekly standing</span>
				<span class="position">${weeklyStanding}</place>
				<span class="pts">${weeklyPts.toFixed(2)} pts</span>
				</div>

				</div>
				</div>`;
			}).join('');

		} catch (e){
			console.error(e);
			app.modules.show('error', 'Error loading your leagues', ['An error has occured'], '/leagues');
		}

	}
}
