const container = document.querySelector('header');

container.querySelector('.emblem').onclick = function(){
	app.navigate('/home');
}

container.querySelector('.home .title').onclick = function(){
	app.navigate(app.modules.get('league').getLeague() ? '/leagues/' + app.modules.get('league').getLeague().identifier : '/');
}

const toggleMenu = function(){
	if (container.classList.contains('opened')){
		closeMenu();
	} else {
		openMenu();
	}
}

container.querySelector('.icon-menu').onclick = toggleMenu;


const openMenu = function(){
		container.querySelector('.icon-menu').innerHTML = 'close';
		container.classList.add('opened');
}


const closeMenu = function(){
		container.querySelector('.icon-menu').innerHTML = 'menu';
		container.classList.remove('opened');
}


//move menu to header if mobile
const mqf = function(){

	let e = window.matchMedia("(max-width: 600px)");
	const menu = document.getElementById('menu');

	if (e.matches && document.getElementById('menu')) {
		container.querySelector('.container').insertBefore(menu, container.querySelector('ul.menu'));
	} else {
		document.getElementById('contentContainer').insertBefore(menu, document.getElementById('contentContainer').querySelector('section'));
	}
}

mqf();
window.addEventListener('resize', mqf);

export default {
	toggleMenu,
	openMenu,
	closeMenu
}