const formRegister = document.querySelector('#auth form.register');
const formLogin= document.querySelector('#auth form.login');
const formRecover = document.querySelector('#auth form.recover');
const formRecoverExec = document.querySelector('#auth form.recover_exec');
import Swal from 'sweetalert2'

formRegister.addEventListener('submit', async function(evt){

	evt.preventDefault();

	app.modules.block();

	if (this.elements.password.value.length < this.elements.password.getAttribute('minLength')){
		app.modules.unblock();
		return app.modules.formError(this, `Password needs to be at least ${this.elements.password.getAttribute('minLength')} in length`, [this.elements.password, this.elements.password2]);
	}

	if (this.elements.password.value != this.elements.password2.value){
		app.modules.unblock();
		return app.modules.formError(this, 'Passwords do not match', [this.elements.password, this.elements.password2]);
	}

	let postData = {
		email: this.elements.email.value.trim(),
		password: this.elements.password.value.trim(),
		name: this.elements.name.value.trim(),
	}

	try{
		await app.api.post(this.getAttribute('action'), postData);
		app.modules.show('checkEmail', postData.email);
	} catch (e){

		console.error(e);

		switch(e.status){
			case 409:
			app.modules.formError(this, `Could not register. Make sure you are using a valid e-mail address that hasn't been used previously!`);
			break;

			default:
			app.modules.formError(this, 'An error occurred while trying to register your account. Please try again later!');
			break;
		}
	}

	app.modules.unblock();

});


formLogin.addEventListener('submit', async function(evt){
	evt.preventDefault();

	app.modules.block();

	let email = this.querySelector('input[type="email"]').value;
	let password = this.querySelector('input[type="password"]').value;

	try{
		await app.auth.login(email, password);
		app.navigate('/just_logged_in');
	} catch (e){

		if (!e.status) e = {};

		switch(e.status){
			case 409:
			app.modules.formError(this, 'Invalid credentials. Please try again!');
			break;

			default:
			app.modules.formError(this, 'Could not login, please try again!');
			break;
		}
	}

	app.modules.unblock();

});


formRecoverExec.addEventListener('submit', async function(evt){
	evt.preventDefault();

	app.modules.block();

	let password = this.elements.password.value.trim();
	let password2 = this.elements.password2.value.trim();

	if (password != password2) {
		app.modules.unblock();
		return app.modules.formError(this, 'Passwords do not match!');
	}

	if (this.elements.password.value.length < this.elements.password.getAttribute('minLength')){
		app.modules.unblock();
		return app.modules.formError(this, `Password needs to be at least ${this.elements.password.getAttribute('minLength')} in length`, [this.elements.password, this.elements.password2]);
	}

	try{

		let postData = {
			password: password,
			token_id: app.currentRoute.params.tokenId,
			token_value: app.currentRoute.params.tokenValue
		}

		await app.api.post(this.getAttribute('action'), postData);
		app.navigate('/auth');
	} catch (e){
		if (!e.status) e = {};

		switch(e.status){
			case 409:
			app.modules.show('error', 'Could not change password', ['Your recovery URL is either expired or invalid.']);
			break;

			default:
			app.modules.show('error', 'Oups... an error has occured!', ['We were unable to change your password!', 'You might want to try again later!']);
			break;
		}
	}

	app.modules.unblock();

});

formRecover.addEventListener('submit', async function(evt){
	evt.preventDefault();

	app.modules.block();

	try{
		let email = this.elements.email.value.trim();
		await app.api.post(this.getAttribute('action'), {email: email});
		app.modules.show('checkEmailRecover', email);
	} catch (e){
		console.error(e);
		switch(e.status){
			case 409:
			app.modules.formError(this, `We could not send you a recovery email. Make sure your e-mail address is valid and your account is active.`);
			break;

			default:
			app.modules.formError(this, 'An error occurred. Please try again later!');
			break;
		}
	}

	app.modules.unblock();

});

export default {

	show: function(module){
		switch(module){
			case 'welcome':
			if (app.auth.getToken()) return app.navigate('/');
			app.setTitle(`Welcome to Fantasy League Survivor`);
			break;

			case 'recover':
			app.setTitle(`Recover password`);
			break;

			case 'register':
			app.setTitle(`Sign up`);
			break;

		}

		document.querySelectorAll('#auth .auth-section').forEach(function(el){
			el.style.display = 'none';
		});
		console.log(`#auth .auth-section.${module}`)
		document.querySelector(`#auth .auth-section.${module}`).style.display = 'block';

	}
}
