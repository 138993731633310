const MODULES = {
	home: require('./home').default,
	auth: require('./auth').default,
	checkEmail: require('./checkEmail').default,
	error: require('./error').default,
	schedule: require('./schedule').default,
	users: require('./users').default,
	profile: require('./profile').default,
	letsgetyoustarted: require('./letsgetyoustarted').default,
	leagues: require('./leagues').default,
	league: require('./league').default,
	team: require('./team').default,
	leagueMembers: require('./league_members').default,
	matchup: require('./matchup').default,
	players: require('./players').default,
	leaderboard: require('./leaderboard').default,
	fakeTime: require('./fake_time').default,
	header: require('./header').default,
	stats: require('./stats').default,
}

let curModule;

export default {

	get: function(key){
		return MODULES[key];
	},

	show: async function(key){

		MODULES.header.closeMenu();

		document.querySelector('#menu').style.display = '';

		document.querySelectorAll('section').forEach(function(item){
			item.style.display = 'none';
			item.classList.remove('fade-in');
		});

		//mark menu item as selected if need be based on current url
		let menu = document.getElementById('menu');
		if (menu.style.display != 'none'){
			menu.querySelectorAll('div.item').forEach(function(item){
				if (app.parseURLVariables(item.dataset.url) == window.location.pathname) return item.classList.add('item-selected');
				item.classList.remove('item-selected');
			});
		}

		let container = document.querySelector(`section#${key}`);

		if (container) container.style.display = '';

		app.modules.block();

		if (MODULES[key] !== undefined && MODULES[key].show){
			if (curModule && curModule.hide) {
				curModule.hide();
			}
			await MODULES[key].show.apply(this, [].slice.call(arguments, 1));
			curModule = MODULES[key];
		}

		app.modules.unblock();

		if (container) container.classList.add('fade-in');

	},

	hide: function(key){
		MODULES[key].hide();
	},

	disableForm: function(form){
		form.classList.add('disabled');
		[...form.querySelectorAll('input')].map(function(item){

			if (item.parentNode.tagName == 'DIV' && item.parentNode.classList.contains('input')){
				item.parentNode.classList.add('input-disabled');
			}

			item.setAttribute('disabled', 'disabled');
		});
	},

	enableForm: function(form, removeErrors = false){

		if (removeErrors) this.formErrorRemove(form);

		form.classList.remove('disabled');
		[...form.querySelectorAll('input')].map(function(item){
			if (item.parentNode.tagName == 'DIV' && item.parentNode.classList.contains('input')){
				item.parentNode.classList.remove('input-disabled');
			}
			item.removeAttribute('disabled');
		});
	},

	formErrorRemove: function(form){
		form.querySelector('div.error').style.display = 'none';
		form.querySelectorAll('div.input').forEach(function(item){
			item.classList.remove('error');
		});
	},

	formClear: function(form, removeErrors = true){
		if (removeErrors) this.formErrorRemove(form);
		[...form.elements].forEach(function(el){
			if (['submit', 'button'].indexOf(el.type) != -1) return;
			el.value = '';
		});
	},

	formError: function(form, message, highlightElements = []){

		highlightElements.map(function(item){
			if (typeof item == 'string'){
				form.querySelector(item).parentNode.classList.add('error');
			} else {
				item.parentNode.classList.add('error');
			}
		});

		let error = form.querySelector('div.error');
		error.innerText = message;
		error.style.display = 'block';

		app.modules.enableForm(form);

	},

	selectOption(select, optionValue){

		if (typeof select == 'string') select = document.querySelector(select);

		for (let i in select.options){
			if (select.options[i].value == optionValue){
				select.selectedIndex = i;
				return;
			}
		}

		throw `option not found ${optionValue} in ${select}`;
	},

	//simple "block" ui with an overlay ... loading api purposes
	block: function(){
		document.getElementById('overlay').style.display = 'block';
	},

	unblock: function(){
		document.getElementById('overlay').style.display = 'none';
	}

}
