"use strict";

import API from './api'
import AUTH from './auth'
import MODULES from './modules/index'

export default {

	current_route: null,
	api: API,
	modules: MODULES,
	auth: AUTH,

	//ads contextual url support
	parseURLVariables: function(url){
		if (this.modules.get('league').getLeague()){
			url = url.replace('$league_id$', this.modules.get('league').getLeague().identifier);
			url = url.replace('$selected_league_url$', `/leagues${this.modules.get('league').getLeague()}`);
		}

		return url;
	},

	setTitle: function(text){
		document.title = `${text} | Fantasy League Survivor`;
	},

	navigate: function(){},

	init: async function(){

		document.querySelectorAll('section').forEach(function(item){
			item.style.display = 'none';
		});

		document.getElementById('overlay').style.opacity = 0.8;

	//header setup for input docs
	document.querySelectorAll('div.input input').forEach(function(input){
		let showHeader = function(){

			let header = this.parentElement.querySelector('div.header');

			if (this.value.trim() == '') {
				header.style.display = 'none';
				return;
			}

			header.innerText = this.getAttribute('placeholder');
			header.style.display = 'block';
		}

		input.addEventListener('change', showHeader);
		input.addEventListener('focus', showHeader);
		input.addEventListener('blur', showHeader);
		input.addEventListener('keydown', showHeader);
	});

	document.querySelectorAll('a').forEach(function(item){
		item.addEventListener('click', function(event){
			let x = window.location.protocol + '//' + window.location.host;
			if (this.href.indexOf(x) != 0) return;
			app.navigate(this.href.replace(x, ''));
			event.preventDefault();
		});
	});

	document.querySelectorAll('#menu div.item').forEach(function(item){
		item.addEventListener('click', function(event){
			app.navigate(app.parseURLVariables(this.dataset.url));
		});
	});

	//check user type
	if (app.auth.getUserId()){

		try{
			let data = await app.auth.getUser();
			if (data.user.isAdmin) document.body.classList.add('isAdmin');
			if (data.allow_fake_time) app.modules.get('fakeTime').enable();
		} catch (e){
			console.error(e);
		}

	}


	app.modules.unblock();
	this.app.navigate();
},


}
