import moment from 'moment-timezone';
import Tablesort from 'tablesort'
import WeekSelector from '../components/week_selector';
const queryString = require('query-string');
const TEAMS = require('../constants').TEAMS;

new Tablesort(document.querySelector(`#schedule table`));

const container = document.getElementById('schedule');

const weekSelector = new WeekSelector(container.querySelector('select'));
weekSelector.setOnChangeListener(value => {
	window.history.pushState(null, null, `${window.location.pathname}?week=${value}`);
	showWeek(value);
});

function showWeek(week){
	container.querySelectorAll(`table tbody tr`).forEach(function(item){
		item.style.display = (item.dataset.week == week ? '' : 'none');
	});
}

export default {
	show: async function(season){

		app.setTitle(`Games schedule`);

		container.querySelector(`h1`).innerText = '';

		container.querySelector(`table tbody`).innerHTML = '';
		container.querySelector(`p.details`).innerText = '';

		let table = container.querySelector(`table tbody`);

		app.setTitle(`${season.identifier} NFL season`);
		container.querySelector(`h1`).innerText = `${season.identifier} NFL season`;
		container.querySelector(`p.details`).innerText = `Season start: ${moment(new Date(season.start)).format('dddd, MMMM Do YYYY')}`;

		let weeks = [];

		season.games.map(function(game){

			if (weeks.indexOf(game.week) == -1) weeks.push(game.week);
			let row = table.insertRow();
			row.dataset.week = game.week;

			let cell = row.insertCell();
			cell.innerText = TEAMS[game.teams[0]] ? TEAMS[game.teams[0]].full_name : game.teams[0];

			cell = row.insertCell();
			cell.innerText = TEAMS[game.teams[1]] ? TEAMS[game.teams[1]].full_name : game.teams[1];

			cell = row.insertCell();
			cell.innerText = moment(new Date(game.date)).local().format('MM/DD, HH:mm');

			cell = row.insertCell();
			if (game.odds) cell.innerText = game.odds.details;

			cell = row.insertCell();
			if (game.weather && game.weather.description){
				cell.innerHTML = `${game.weather.description} ${game.weather.temperature ? `&#127777; ${game.weather.temperature}f ` : ''}
				${game.weather.wind ? ', &#127788;'+game.weather.wind + 'mph' : ''}`;
			}

			cell = row.insertCell();
			cell.classList.add('score');
			if (game.score){
				cell.innerHTML = `<div>${game.score.join('-')} ${game.status.replace('_', ' ')}</div>`;
			} else {
				cell.innerHTML = `<div>${game.status}</div>`;
			}

		});

		weekSelector.populate(season)

		const qs = queryString.parse(window.location.search);

		let sw;

		if (qs.week){
			sw = qs.week;
		} else {
			sw = season.current_week || season.next_week || season.last_week;
			if (!season.current_week && season.last_week && new Date().getDay() < 3) sw  = season.last_week;
		}

		weekSelector.select(sw);
		showWeek(sw);
	}
}
