const ROUTES = {

	'/': async function (app) {
		if (app.auth.getToken()) return app.navigate('/leagues');

		return app.navigate('/home');
	},

	'/home': async function (app) {
		return app.modules.show('home');
	},

	'/just_logged_in': async function (app) {
		//does this user come from an invite
		let wantedToJoinLeague = localStorage.getItem('wanted_to_join_league');
		localStorage.removeItem('wanted_to_join_league');
		app.navigate(wantedToJoinLeague ? wantedToJoinLeague : '/leagues');
	},

	'/profile': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		return app.modules.show('profile');
	},

	'/auth': async function (app) {
		return app.modules.show('auth', 'welcome');
	},

	'/auth/register': async function (app) {
		if (app.auth.getToken()) return app.navigate('/auth');
		return app.modules.show('auth', 'register');
	},

	'/auth/recover': async function (app) {
		if (app.auth.getToken()) return app.navigate('/auth');
		return app.modules.show('auth', 'recover');
	},

	'/auth/email/recover/:tokenId/:tokenValue': async function (app) {
		return app.modules.show('auth', 'recover_exec', this.params.tokenId, this.params.tokenValue);
	},

	'/auth/email/activate/:tokenId/:tokenValue': async function (app) {
		try {
			await app.auth.activateEmail(this.params.tokenId, this.params.tokenValue);
			app.navigate('/just_logged_in');
		} catch (e) {
			if (!e.status) e = {};

			switch (e.status) {
				case 409:
					app.modules.show('error', 'Could not activate your account', 'The activation URL has expired or is invalid.');
					break;

				default:
					app.modules.show('error', 'An error has occured!', ['We were unable to activate your account at this time', 'Please try again late']);
					break;
			}
		}
	},

	'/auth/email/validate/:tokenId/:tokenValue': async function (app) {
		try {
			await app.auth.validateEmail(this.params.tokenId, this.params.tokenValue);
			app.navigate('/profile');
		} catch (e) {
			if (!e.status) e = {};

			switch (e.status) {
				case 409:
					app.modules.show('error', 'Could not validate your new e-mail address', 'The activation URL has expired or is invalid.');
					break;

				default:
					app.modules.show('error', 'An error has occured!', ['We were unable to validate your email at this time', 'Please try again late']);
					break;
			}
		}
	},

	'/schedule': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		const {season} = await models.season.get();
		return app.modules.show('schedule', season);
	},

	'/admin/users': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		return app.modules.show('users');
	},

	'/admin/stats': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		return app.modules.show('stats');
	},

	'/rules': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		return app.modules.show('rules');
	},

	'/leagues': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		return app.modules.show('leagues');
	},

	'/leagues/:id/leaderboard': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		const {league} = await models.league.get(this.params.id);
		const {season} = await models.season.get();

		return app.modules.show('leaderboard', league, season);
	},

	'/leagues/:id/matchup': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		const {league} = await models.league.get(this.params.id);
		const {season} = await models.season.get();
		return app.modules.show('matchup', league, season);
	},

	'/leagues/:id': async function (app) {
		app.navigate('/leagues/' + this.params.id + '/leaderboard');
	},

	'/leagues/:id/team': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		const {league} = await models.league.get(this.params.id);
		const {season} = await models.season.get();
		return app.modules.show('team', league, season, app.modules.get('league').getTeam()._id);
	},

	'/leagues/:id/team/:tid': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		const {league} = await models.league.get(this.params.id);
		const {season} = await models.season.get();
		return app.modules.show('team', league, season, this.params.tid);
	},

	'/leagues/:id/members': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		const {league} = await models.league.get(this.params.id);
		const {season} = await models.season.get();
		return app.modules.show('leagueMembers', league, season);
	},

	'/leagues/:id/join/:password': async function (app) {
		if (!app.auth.getToken()) {
			localStorage.setItem('wanted_to_join_league', window.location.href);
			return app.navigate('/auth');
		}

		return app.modules.show('letsgetyoustarted', 'join', this.params);
	},

	'/leagues/:id/players': async function (app) {
		if (!app.auth.getToken()) return app.navigate('/auth');
		const {league} = await models.league.get(this.params.id);
		console.log('players', league)
		const {season} = await models.season.get();
		return app.modules.show('players', league, null, season);
	},

}


let models;
const currentYear = moment().year();

const createModels = (app) => ({
	league: createCache({
		fetch: (key) => {
			return () => app.api.get(`/fantasy/league/${key}`);
		},
		onSuccess: (data) => {
			if (app.modules.get('league').getLeague() !== data.league) {
				app.modules.get('league').set(data.league);
			}
		},
		onError: (e) => {
			console.error(e);

			if (e.status == 401) {
				app.modules.unblock();
				return app.modules.show('error', `You do not have access to this league.`, ['You are not a member of this league'], '/');
			}

			app.modules.show('error', `There was an error loading this league`, ['Could not load this league']);
			return app.modules.unblock();
		}
	}),
	season: createCache({
		defaultKey: currentYear,
		fetch: () => {
			const url = `/season`
			return () => app.api.get(url);
		},
		onError: () => {
			console.error(e);
			app.modules.show('error', `There was an error loading this season`, ['Could not load this season']);
			return app.modules.unblock();
		}
	})
})

async function init(app) {
	models = createModels(app)
	for (let route in ROUTES) {
		addRoute(route, async function () {
			app.currentRoute = this;
			return ROUTES[route].bind(this)(app);
		});
	}

	window.onpopstate = function (event) {
		console.log('event', event)
		navigate(app, location.pathname);
	}

	if (app.auth.getToken()) document.body.classList.add('isLoggedIn');
}

import RouteRecognizer from 'route-recognizer'
import createCache from './cache';
import moment from 'moment';

const router = new RouteRecognizer();

function addRoute(route, f) {
	router.add([{
		path: route,
		handler: f
	}]);
}

async function navigate(app, pathname = window.location.pathname) {
	let result = router.recognize(pathname);

	if (window.location.pathname != pathname) window.history.pushState(null, null, pathname);
	if (pathname !== '/') {
		app.modules.hide('home')
	}
	if (result && result[0]) {

		app.modules.block();

		window.scrollTo({ top: 0, left: 0 });

		try {
			await result[0].handler(result[0]);
		} catch (e) {
			console.error(e);
			app.modules.show('error', `Oops... we've encountered an issue`, `There was a problem loading this URL [${e && e.status ? e.status : ''}]`);
		}


		app.modules.unblock();

	} else {
		app.modules.show('error', `Oops... can't find page`, `There was a problem loading this URL`);
		document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex');
	}

	return false;
}

export { navigate, init }
