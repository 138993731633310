const container = document.getElementById('error');

export default {
	show: function(title = 'Oups...', content = [], backUrl = '/'){

		app.modules.unblock();

		document.getElementById('menu').style.display = 'none';

		if (!content) content = [];

		error.querySelector('a').href = backUrl;

		document.querySelector(`#error h1`).innerText = title;

		if (content && typeof content == 'string') content = [content];

		error.querySelector('div.content').innerHTML = '';

		content.map(function(item){
			let p = document.createElement("p");
			p.appendChild(document.createTextNode(item));
			error.querySelector('div.content').appendChild(p);
		});
	}
}
