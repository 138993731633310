class Modal {
	constructor(el) {
		this.set(el);
        this.hide();
	}

	get() {
		return this.el;
	}

	set(el) {
		if (el) {
			this.el = el;
            this.el.classList.add('modal');
            this.bgEl = document.createElement('div');
            this.bgEl.classList.add('modal__bg');
            this.bgEl.addEventListener('click', this.hide.bind(this));
            const keyDown = (e) => {
                if (e.key === "Enter") {
                    this.hide();
                }
            }
            this.bgEl.addEventListener('keydown', keyDown);
            this.el.appendChild(this.bgEl)

            this.closeEl = document.createElement('button');
            this.closeEl.classList.add('material-icons','close-button')
            this.closeEl.innerHTML = 'close';
            this.closeEl.addEventListener('click', this.hide.bind(this));
            this.closeEl.addEventListener('keydown', keyDown);
            this.el.appendChild(this.closeEl)

            this.contentEl = document.createElement('div');
            this.contentEl.classList.add('modal__content');
            this.el.appendChild(this.contentEl)
		} else {
			console.error('No element defined for modal');
		}
	}

    show () {
        this.el.classList.remove('modal--inactive');
        this.el.classList.add('modal--active');
        this.bgEl.setAttribute('tabindex', 0)
        this.bgEl.focus();
    }

    hide () {
        this.el.classList.remove('modal--active');
        this.el.classList.add('modal--inactive');
        while (this.contentEl.firstChild) {
            this.contentEl.removeChild(this.contentEl.firstChild);
        }
        this.bgEl.removeAttribute('tabindex');
        if (this.onClose) {
            this.onClose();
        }
    }

    render (children) {
        this.contentEl.innerHTML = children;
    }

    get onClose() {
        return this.closeHandler;
    }

    set onClose (callback) {
        this.closeHandler = callback
    };
}

export default Modal