import queryString from 'query-string';
import moment from 'moment';

const getWeek = (weekParam, season) => {
    const qs = queryString.parse(window.location.search);
    let week = weekParam;
    if (!week && qs.week){
        // console.log('qs.week', qs.week)
        week = parseInt(qs.week);
    } else {
        // console.log(week, season.current_week, season.next_week, season.last_week)
        if (!week) week = season.current_week || season.next_week || season.last_week;
        // if (week == season.next_week && !season.current_week && season.last_week && new Date().getDay() < 3 && week) week  = season.last_week;
    }

    return week;
}

const findGameFromSeasonData = (week, team, seasonData) => {
	let vsGame;
	for (let i in seasonData.games) {
		let game = seasonData.games[i];
		if (game.week != week) continue;
		let p = game.teams.indexOf(team);
		if (p == -1) continue;
		vsGame = game;
		break;
	}

	return vsGame;
}

const isHomeTeam = (team, gameData) => {
	return gameData.teams.indexOf(team) == 0;
}

const gameIsLive = (gameData) => {
	return gameData && !gameIsFinished(gameData) && gameData.status !== 'scheduled'
}

const gameIsScheduled = (gameData) => {
	return gameData && gameData.status === 'scheduled'
}

const gameIsFinished = (gameData) => {
	return gameData && (gameData.status === 'finished' || gameData.status === 'final' || gameData.status === 'f/ot')
}

const gameIsFinishedInOvertime = (gameData) => {
	return gameData && gameData.status === 'f/ot';
}

const getGameTime = (gameData) => {
	return gameData && gameData.quarter && !gameIsScheduled(gameData) ? (gameData.timeRemaining ?  gameData.timeRemaining + ' ' : "") + (Number.isInteger(Number(gameData.quarter)) ? Number(gameData.quarter).nth() : gameData.quarter) + ' ' : '';
}

const getGameStatusString = (team, gameData) => {
	const {score, date} = gameData || {};
    const isLive = gameIsLive(gameData);
    const isFinished = gameIsFinished(gameData);
	const isFot = gameIsFinishedInOvertime(gameData);
    const isScheduled = gameIsScheduled(gameData);
	const isHome = isHomeTeam(team, gameData);

	let gameStatus;
	if (isScheduled) {
		const momentDate = moment(new Date(date));
		const dayName = momentDate.format('dddd').substring(0,3);
		const dayTime = momentDate.format('h:mm A');

		gameStatus = `${dayName} ${dayTime}`;
	}
	if (isLive || isFinished) {
		let gameScore = isHome ? [...score] : [...score].reverse();
		if (isFinished) {
			gameStatus = (`Final ${isFot ? 'O/T ' : ''}(${gameScore[0] > gameScore[1] ? 'W' : 'L'})`);
		}
        if (isLive) {
            gameStatus = getGameTime(gameData);
        }
	}

	return gameStatus;
}

const getGameScoreString = (team, gameData) => {
	const {status, score} = gameData || {};
	const isHome = isHomeTeam(team, gameData);

	if (status && status !== 'scheduled' && score) {
		let gameScore = isHome ? [...score] : [...score].reverse();

		return `${gameScore.join('-')}`;
	}
}

const getVsString = (team, gameData) => {
	const isHome = isHomeTeam(team, gameData);
	const vsOrAt = isHome ? 'vs' : '@';
	const vs = isHome ? gameData.teams[1] : gameData.teams[0];
	return `${vsOrAt} ${vs}`
}

export {
    gameIsLive,
    gameIsScheduled,
    findGameFromSeasonData,
    getWeek,
    getGameScoreString,
    getGameStatusString,
    getGameTime,
    getVsString,
    isHomeTeam,
}