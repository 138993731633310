class WeekSelector {
	constructor(el) {
		this.set(el);
	}

	get() {
		return this.el;
	}

	set(el) {
		if (this.el) {
			this.el.removeEventListener('change', this.onChange.bind(this));
		}
		if (el) {
			if (
				el.tagName.toLowerCase() != 'select'
			) {
				console.error('element is NOT a select');
				return;
			}
			this.el = el;
			this.el.addEventListener('change', this.onChange.bind(this));
		} else {
			console.error('No select element defined');
		}
	}

	populate(seasonData, week) {
		if (!this.el) {
			console.error('Cannot populate Week Selector; No select element defined');
			return;
		}
		if (seasonData) {
			this.el.innerHTML = seasonData.weeks.map(function (tw) {
				let disabled = (week != tw && tw > (seasonData.current_week || seasonData.next_week) ? 'disabled' : '');
				return `<option value="${tw}" ${(seasonData.current_week || seasonData.next_week) == tw ? 'selected' : ''} ${disabled}>
				Week ${tw} ${seasonData.current_week == tw ? '*' : ''}
				</option>`;
			});
		}
	}

	onChange () {
		if (this.onChangeCallback) {
			this.onChangeCallback(this.value)
		}
	}

	setOnChangeListener(method) {
		this.onChangeCallback = method;
	}

	select(week) {
		if (!this.el) {
			console.error('Cannot select week; No select element defined');
			return;
		}
		app.modules.selectOption(this.el, week);
	}

	get value () {
		return parseInt(this.el.value);
	}
}

export default WeekSelector