const container = document.getElementById('stats');

export default {
	show: async function(){

		app.modules.get('league').unselectLeague();
		app.setTitle(`System stats | admin`);

		let data = await app.api.get('/stats');
		container.querySelector('div.content').innerHTML = Object.keys(data).map(function(key){
			return `<div class="item"><span>${key}</span> ${data[key]}</div>`;
		}).join('');

	}
}
